import React, { useState, useEffect, useCallback, memo } from "react";
import { fileTrackingService } from "../../services/AssembliesService";
import AssembliesItems from "./AssembliesItems";
import { useDispatch, useSelector } from "react-redux";
import { UpdateLessonType, updateCurrentActivity, updateSubjectContentLoadingOnLesson } from "../../store/actions";
import ScrollBar from "react-perfect-scrollbar";

const SubjectAssemblies = ({
  setQuizId,
  setshow,
  QuizId,
  setrId,
  rId,
  moduleId,
  show,
  getSubContentMethod,
  subassembliesItems,
  assemblyData,
  setModuleId,
  lessonList = [],
  setUpdateLearningMaterialData,
  subjectId,
  isAdmin,
}) => {
  // State management
  const [loading, setLoading] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [buttonCheckedStatus, setButtonCheckedStatus] = useState(0);
  const [subCategoryAcc, setSubCategoryAcc] = useState({ state: null, id: null });
  const [documentId, setDocumentId] = useState([]);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [currentCategoryData, setCurrentCategoryData] = useState([]);

  // Redux
  const dispatch = useDispatch();
  const currentActivity = useSelector(state => state.learningMaterial.current_activity);
 
  useEffect(()=>{
    dispatch(updateSubjectContentLoadingOnLesson("no loading"));
  },[])

  // Handlers
  const handleFileTracking = useCallback((item) => {
    const trackFile = async () => {
      try {
        const payload={
          itemId: item?.isMultiFormat ? item?.assembly_item_id : item?.item_id
        }
        if(item?.isMultiFormat){
          payload.document_id=item?.id
        }
        await fileTrackingService(payload);
        
        setButtonCheckedStatus(true);
        setDocumentId(prev => [...prev, item?.isMultiFormat ? item.assembly_item_id : item?.item_id]);
        setUpdateLearningMaterialData({ 
          state: true, 
          id: item?.assembly_id 
        });
      } catch (error) {
        console.error('File tracking error:', error);
      }
    };

    trackFile();
  }, [setUpdateLearningMaterialData]);

  const updateNextActivity = useCallback((nextActivity) => {
    if (!nextActivity) return;

    // Always update the current activity and lesson type
    dispatch(updateCurrentActivity(nextActivity?.item_id));
    dispatch(UpdateLessonType(nextActivity?.item_type));

    // Handle sub-category state
    if (nextActivity?.sub_children?.length) {
      setSubCategoryAcc({ state: true, id: nextActivity?.item_id });
      // If it has sub-children, set the first child as current
      const firstChild = nextActivity?.sub_children[0];
      dispatch(updateCurrentActivity(firstChild?.item_id));
      dispatch(UpdateLessonType(firstChild?.item_type));
    } else {
      setSubCategoryAcc({ state: false, id: null });
    }
  }, [dispatch]);

  const findNextAssemblyLesson = useCallback(() => {
    const { assembliesItems = [] } = lessonList;
    
    for (let i = 0; i < assembliesItems?.length; i++) {
      const lessons = assembliesItems[i].lessons || [];
      let currentFound = false;
      
      for (let j = 0; j < lessons.length; j++) {
        const lesson = lessons[j];
        
        // Check if current activity is in sub_children
        if (lesson.sub_children?.some(sub => sub.item_id === currentActivity)) {
          currentFound = true;
          
          // Check remaining items in current sub_children
          const subIndex = lesson.sub_children.findIndex(sub => sub.item_id === currentActivity);
          if (subIndex < lesson.sub_children.length - 1) {
            return lesson.sub_children[subIndex + 1];
          }
          
          // Move to next lesson
          if (j < lessons.length - 1) {
            return lessons[j + 1];
          }
          break;
        }
        
        // Check if current activity is the lesson itself
        if (lesson.item_id === currentActivity) {
          currentFound = true;
          
          // If lesson has sub_children, return first sub-child
          if (lesson.sub_children?.length) {
            return lesson.sub_children[0];
          }
          
          // Move to next lesson
          if (j < lessons.length - 1) {
            return lessons[j + 1];
          }
          break;
        }
      }
      
      // If current activity was found in this assembly, check next assembly
      if (currentFound && i < assembliesItems.length - 1) {
        let nextAssembly = null;
      
        // Looping through subsequent assemblies to find one with lessons
        for (let j = i + 1; j < assembliesItems.length; j++) {
          if (assembliesItems[j]?.lessons?.length) {
            nextAssembly = assembliesItems[j];
            break;  // Exit the loop once a next assembly with lessons is found
          }
        }
      
        // If a next assembly with lessons is found, set data
        if (nextAssembly) {
          setCurrentCategoryData(nextAssembly.lessons);
          setModuleId(nextAssembly.lessons[0]?.parent_id);
          return nextAssembly.lessons[0];
        }
      }
    }
    
    return null;
  }, [lessonList, currentActivity, setModuleId]);

  const handleNextActivity = useCallback(() => {
    const nextLesson = findNextAssemblyLesson();
    if (nextLesson) {
      updateNextActivity(nextLesson);
    }
  }, [findNextAssemblyLesson, updateNextActivity]);

  // Update currentCategoryData when lessonList or currentActivity changes
  useEffect(() => {
    if (!lessonList?.assembliesItems?.length) return;

    const assembliesItems = lessonList.assembliesItems;
    
    for (const assembly of assembliesItems) {
      const lessons = assembly?.lessons || [];

      
      for (const lesson of lessons) {
        if (lesson?.item_id === currentActivity) {
          setCurrentCategoryData(lessons);
          return;
        }
        
        const subChildren = lesson?.sub_children || [];
        if (subChildren.some(subChild => subChild?.item_id === currentActivity)) {
          setCurrentCategoryData(subChildren);
          return;
        }
      }
    }
  }, [lessonList, currentActivity]);

  // Update currentLesson when currentActivity or currentCategoryData changes
  useEffect(() => {
    const currentLessonData = currentCategoryData.find(
      lesson => lesson?.item_id === currentActivity
    ) || currentCategoryData?.flatMap(
      lesson => lesson?.sub_children || []
    ).find(
      sub => sub?.item_id === currentActivity
    );

    setCurrentLesson(currentLessonData || null);
  }, [currentActivity, currentCategoryData]);

  // Render
  const renderContent = () => {
    if (!currentLesson) {
      return (
        <div className="learning-material-listing no__content__section" role="tablist" aria-multiselectable="true">
          <div className="subject-header">
            <div className="subject-sub-header ">
              <h3>No Content Available.</h3>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="learning-material-listing learning-material-content">
        <div className="learning-material-body">
          <ul className="sub-ul">
            {/* <ScrollBar> */}
              <AssembliesItems
                {...{
                  handleNextActivity:handleNextActivity,
                  setUpdateLearningMaterialData:setUpdateLearningMaterialData,
                  subassembliesItems,
                  subjectId,
                  assembliesItems: assemblyData,
                  isAdmin,
                  parentSubId: subjectId,
                  setQuizId,
                  QuizId,
                  setrId,
                  rId,
                  show,
                  lesson: currentLesson,
                  setshow,
                  parentSetCurrentVideoId: setCurrentVideoId,
                  lessonindex: currentLesson.item_id,
                  parentFileTracking: handleFileTracking,
                  currentVideoId,
                  getSubContentMethod,
                  buttonCheckedStatus,
                  documentId,
                  handleNextActivity,
                  lessonList
                }}
              />
            {/* </ScrollBar> */}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderContent()}
    </>
  );
};

export default memo(SubjectAssemblies);
