import React, { createRef, useEffect, useState } from 'react'
import $ from "jquery";
import * as Cards from "../components/profile/index";
import SideBar from '../components/common/SideBar'
import Header from '../components/common/Header'
import { getCurrentTimeHomePage, InitalRender, originalValues } from '../components/common/Helper';
import { useDispatch, useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { GetStatementStatus, ProfileImageDetailService, RemoveProfileImage } from '../services/ProfileService';
import { download } from '../utils/FileDownload';
import { BASE_URL } from '../utils/Constants';
import { setUser } from '../store/actions';

const Tabs = [
  {
    first_name : "My",
    last_name : "Profile",
    name: "Personal Information",
    component: "PersonalInformation",
    class: "fa-user",
    id: 1
  },
  {
    first_name : "Educational",
    last_name : "Details",
    name: "Educational Details",
    component: "EducationalDetails",
    class: "fa-graduation-cap",
    id: 2
  },
  {
    first_name : "Sponsor/Account",
    last_name : "Payer",
    name: "Sponsor/Account Payer",
    component: "SponsorPayer",
    class: "fa-piggy-bank",
    id: 3
  },
  {
    first_name : "Certificates",
    last_name : "",
    name: "Certificates",
    component: "Certificates",
    class: "fa-file-alt",
    id: 4
  },
  {
    first_name : "Licenses",
    last_name : "",
    name: "Licenses",
    component: "Licenses",
    class: "fa-address-card",
    id: 5
  },
  {
    first_name: "Account",
    last_name: "Statements",
    name: "Account Statements",
    component: "AccountStatement",
    class: "fa-file-invoice-dollar",
    id: 6,
  }
];


export default function ProfilePage() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  let brand
  const inputProfileFileRef = createRef();
  const [state, setState] = useState({
    currentTab: 1,
    currentComponent: "PersonalInformation"
    })
  const [disableStatement, setDisableStatement] = useState(false);

  if (localStorage.getItem("brandVariables") != null) {
    brand = JSON.parse(localStorage.getItem("brandVariables"));
    //console.log("details--- ", details.email);
  }
  
  useEffect(() =>{
    if(user.netsuite_id && user.netsuite_id!=""){
      GetStatementStatus({id:user.netsuite_id}).then((res)=>{
        if(res.status == 200){
          const respData = res.data.data;
          if(respData?.BadDebt === true || respData?.HandedOver === true){
            setDisableStatement(true)
          }          
        }
      }).catch((err)=>{
        console.log(err)
      })
    }
  },[])

  const handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  const handleImage = () => {
    this.inputProfileFileRef.current.click();
  };

  const handleProfileImage = (e) => {
    if (e.target.files.length) {
      const formInputs = new FormData();
      formInputs.append("image", e.target.files[0]);
      Swal.fire({
        title: "File Uploading....",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      ProfileImageDetailService(formInputs)
        .then((res) => {
          Swal.close();
          dispatch(setUser(res.data));
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {});
        })
        .catch((error) => {
          console.log(error)
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error?.response?.data?.message,
          });
        });
    }
  };
  
  const removeProfileImage = () => {
    RemoveProfileImage()
      .then((res) => {
        Swal.close();
        dispatch(setUser(res.data));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Removed successfully",
        }).then((result) => {});
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        }).then((result) => {});
      });
  };

  const downloadFile = async () => {
    let fileName = "my_card.pdf";
    const absolutePath = BASE_URL+'/'+ user?.electronic_student_card.replace("/home/myaie/public_html/","");
    let value = await download(absolutePath, fileName);
  };
  
  useEffect(()=>{  
    if(this?.props?.user?.netsuite_id && this?.props?.user?.netsuite_id != ""){
      GetStatementStatus({id:this.props.user.netsuite_id}).then((res)=>{
        if(res.status == 200){
          const respData = res.data.data;
          if(respData?.BadDebt === true || respData?.HandedOver === true){
            this.setState({disableStatement : true})
          }          
        }
      }).catch((err)=>{
        console.log(err)
      })
    }
  })

  const handleViewCard = () =>{
    window.open(BASE_URL + "/" + user?.electronic_student_card.replace("/home/myaie/public_html/","") + "#zoom=75", "_blank")
  }

  const handleTab = (tab) => {
    setState({ currentTab: tab.id, currentComponent: tab.component });
    $("body").removeClass("hide-subject-sidebar");
  };
  
  const handleTabContent = (component, key) => {
    if(component == "EducationalDetails" && (brand?.domain_url.includes("learnfast") || brand?.domain_url.includes("cadco") || (user?.student_brand_url && (user?.student_brand_url.includes("learnfast") || user?.student_brand_url.includes("cadco"))))){
      return <></>
    }
    const Card = Cards[component];
    return <Card key={key} />;
  };

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="profile-wrapper">
            <div className="profile-left bg-white">
              <button
                className="close-btn-sidebar"
                onClick={() =>{handleSideBar()}}
              >
                <i className="close-bar close-bar-1"></i>
                <i className="close-bar close-bar-2"></i>
                <i className="close-bar close-bar-3"></i>
              </button>

              <div className='student-detail-wrapper'>                
                <div className="upload-drop-down-wrap profile-image-option">
                  <div className="dropdown">
                    <button
                      title="Profile Picture"
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fal fa-ellipsis-v"></i>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <ul className="list-unstyled">
                        <li title="Upload Profile Picture">
                          <div className="upload-image-option">
                            <input
                              type="file"
                              name="avtar"
                              accept="image/*"
                              ref={inputProfileFileRef}
                              onChange={(e) =>{handleProfileImage(e)}}
                            />
                            <i
                              className="fal fa-camera"
                              onClick={() => {handleImage()}}
                            ></i>
                            Upload Profile Picture
                          </div>
                        </li>
                        {user?.photo !== "https://myaie.acundefined" ? (
                          <li title="Remove Profile Picture">
                            <button
                              onClick={() =>{removeProfileImage()}}
                            >
                              <i className="fal fa-trash-alt"></i>Remove
                              Profile Picture
                            </button>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="profile-img-container">                                              
                  {user?.photo === null ? InitalRender(originalValues(user?.first_name + " " + user?.last_name, 23)) : <img
                    src={user?.photo}
                    alt="" 
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.closest('.profile-img-container').append(InitalRender(originalValues(user?.first_name + " " + user?.last_name, 23)));
                      currentTarget.remove();
                    }}
                  />}
                  <span className={`portal-status-icon ${user?.activity_status?.toLowerCase() == 'online' ? 'online-u' : user?.activity_status?.toLowerCase() == 'donotdisturb' ? 'donotdisturb-u' : 'offline-u'}`}></span>
                </div>                
                <div className='profile-detail-container'>
                  <h4>{user?.first_name} {user?.last_name}</h4>
                  <p>#{user?.student_crm_id}</p>
                  <p>Student Status: <span className={'student-status'+(user?.status=="Active" ?  " active" : "inactive")}>{user?.status ? user?.status : "NA"}</span></p>                  
                </div>                
              </div>              
              {(user?.electronic_student_card !== undefined && user?.electronic_student_card !== "") ?
                <div className='profile-detail-actions'>
                    <button className='btn btn-primary mb-2' onClick={() =>{downloadFile()}}>
                      <i className="fal fa-id-card"></i> Download Student Card
                    </button>
                    <button className='btn btn-white-bordered btn-back' onClick={() =>{handleViewCard()}}>
                      <i className="fal fa-id-card"></i> View Student Card
                    </button>
                </div>
              :null}
              <ul className="nav nav-pills flex-column profile-tab-pills">
                {Tabs.map((tab) =>{
                  if((tab.id == 2 || tab.id == 6) && (brand?.domain_url?.includes("learnfast") || brand?.domain_url?.includes("cadco") || (user?.student_brand_url && user?.student_brand_url?.includes("learnfast")) || (user?.student_brand_url && user?.student_brand_url?.includes("cadco")))){
                    return null
                  }
                  return(
                  <li className="nav-item" title={tab.name} key={tab.id}>
                    {tab.id === 6 && (disableStatement === true || user?.status == "On Hold") ? 
                      <a
                        className={
                          "nav-link disabled"
                        }
                        data-toggle="tab"
                        href={"#profile-tab-" + tab.id}
                      >
                        <i className={"fa " + tab.class}></i>
                        {tab.name}
                      </a>
                      :
                      <button
                        className={
                          "w-100 nav-link " + (tab.id == state.currentTab ? "active" : "")
                        }
                        data-toggle="tab"
                        onClick={()=>{handleTab(tab)}}
                      >
                        <i className={"fa " + tab.class}></i>
                        {tab.name}
                      </button>
                    }
                  </li>
                )})}

              </ul>
            </div>
            <div className="profile-right">
              <div className="profile-header">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <h4 className="text-left-align landing-heading heading_color_dynamic">
                    {Tabs[state.currentTab - 1]?.first_name} <span>{Tabs[state.currentTab - 1]?.last_name}</span>
                  </h4>
                  <div className="text-md-right">
                    <p className="">
                      {" "}
                      {getCurrentTimeHomePage(Date().toLocaleString())}{" "}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="tab-content  profile-tab-content">
                  <div
                    id={"profile-tab-" + state.currentTab}
                    className="tab-pane active"
                  >                    
                    {handleTabContent(state.currentComponent,state.currentTab)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
