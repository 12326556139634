import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { download } from "../../utils/FileDownload";
import moment from "moment";
import randomColor from "randomcolor";
import PuzzleImg from '../../assets/images/puzzle-icon.svg';
import { TrimText } from "../common/TrimText";
import { formatDateRange, getSubjectStatus } from "../../utils/commonFunction";
import CommonCourseComponents from "./CommonCourseComponents";
import { stat } from "fs";
import UserHover from "../common/UserHover";
import UnknownStudent from "../../assets/images/ic_unknown_user.png"
import UnknownStudentStatus from "../../assets/images/ic_unknown_user_online_status.png"
import { InitalRender, originalValues } from "../common/Helper";
import { ColorRender } from "../ticket/StatusRender";
import { BASE_URL } from "../../utils/Constants";
import ViewProfile from "./ViewProfile";
import { clearToggles } from "../../store/actions";
const stc = require('string-to-color');
const downloadFile = async (absolutePath, fileName) => {
  await download(absolutePath, fileName);
};

function QualificationBox({  qualification, state }) {
  const [color, setColor] = useState(null);
  useEffect(() => {
    setColor(
      randomColor({
        luminosity: "dark",
      })
    );
  }, [qualification])

  function createAbbreviation(name = "") {
    return name.split(" ")[0].toString().toUpperCase().substring(0, 1) + (name.split(" ")[1] ? name.split(" ")[1].toString().toUpperCase().substring(0, 1) : "");
  }

  const handleCardClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const calculateDuration = (start, end) => {
    const startMoment = moment.unix(start);
    const endMoment = moment.unix(end);
    const duration = moment.duration(endMoment.diff(startMoment));
  
    const years = Math.floor(duration.asYears());
    const months = Math.floor(duration.asMonths()) % 12;
    const days = Math.floor(duration.asDays()) % 30;
  
    const parts = [];
    if (years > 0) parts.push(`${years}${years > 0 ? 'y' : ''}`);
    if (months > 0) parts.push(`${months}${months > 0 ? 'm' : ''}`);
    if (days > 0) parts.push(`${days}${days > 0 ? 'd' : ''}`);
  
    return parts.join(' ');
  };
  

  return (
    <>
      <div className="tab-content" id="pills-tabContent">
                <div className="mycourses-ul-list m-0 d-block">
                  <div className="row row-custome couses-newrw-lists">
                     {!state?.showQual ? (
                      <>
                         {state?.shortCourse?.map((ele, ind) => {
                           var icon = getSubjectStatus(ele?.icon);
                          return (
                            <div className="col-lg-3 col-md-6 col-xs-12 qualification-box" key={ind} title={ele?.course}>
                              <div className="couses-renew-card home-bx-card qualification-card" onClick={handleCardClick}>
                                <Link to={`/shortCourse/${ele?.id}`} className="d-flex flex-column justify-content-between h-100">
                                  <div className="d-flex flex-column">
                                    {ele?.code ? <div className="couses-card">
                                      <div className="short-course-title" title={ele?.code}>
                                        <span className="tittle">{ele?.code}</span>
                                      </div>
                                      {!ele?.code && ele?.commentCount != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                                        <span className="nav-fa-comments card-comments">
                                          <small className="notify-count">{ele?.commentCount ? ele?.commentCount < 10 ? ele?.commentCount : "9+" : 0}</small>
                                          <i className="fal fa-comments notification-icon"></i>
                                        </span>
                                      </div>}
                                    </div> : null}

                                    <div className="program-tittle">
                                      <h2 title={ele?.course ? ele?.course : ""}>{ele?.course ? TrimText(ele?.course, 30) : ""}</h2>
                                      {ele?.commentCount != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                                        <span className="nav-fa-comments card-comments">
                                          <small className="notify-count">{ele?.commentCount ? ele?.commentCount < 10 ? ele?.commentCount : "9+" : 0}</small>
                                          <i className="fal fa-comments notification-icon"></i>
                                        </span>
                                      </div>}
                                    </div>
                                    <p className="program-type">
                                      {/* <span className="couse_i_in"><i className="fal fa-book"></i></span> */}
                                      {ele?.type ? ele?.type : ""}
                                    </p>

                                    <div className="d-flex align-items-center">
                                      {ele?.type === "Self-Paced" ? (
                                        <p className="course__card__duration">
                                          <span className="couse_i_in">
                                            <i className="fal fa-clock" style={{ marginRight: "5px" }}></i>
                                          </span>
                                          {ele?.subject_duration ? `${ele?.subject_duration} months` : "-"}
                                        </p>
                                      ) : (
                                        <p className="course__card__duration">
                                          <span className="couse_i_in">
                                            <i className="fal fa-calendar-alt" style={{ marginRight: "5px" }}></i>
                                          </span>
                                          {ele?.start && ele?.end ? (
                                            <>
                                              {formatDateRange(ele?.start, ele?.end)}
                                            </>
                                          ) : "Invalid Date"}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="qualification-view align-items-start">
                                      {(ele?.lecturer_name) ? <ViewProfile
                                          user={{
                                            lead_photo: ele?.lecturer_photo,
                                            prog_lead_photo: ele?.lecturer_photo,
                                            prog_lead_name: ele?.lecturer_name,
                                            lead_activity_status: ele?.lecturer_status,
                                            lead_email: ele?.lecturer_email,
                                            lead_mobile: ele?.lecturer_mobile,
                                            coach_name: ele?.intake_coach_name,
                                            prog_coach_name: ele?.intake_coach_name,  
                                            coach_photo: ele?.intake_coach_photo,
                                            coach_email: ele?.intake_coach_email,
                                            coach_mobile: ele?.intake_coach_mobile,
                                            supp_photo: ele?.intake_supp_photo,
                                            supp_fullname: ele?.intake_supp_name,
                                            supp_activity_status: ele?.intake_supp_status,
                                            supp_email: ele?.intake_supp_email,
                                            supp_mobile: ele?.intake_supp_mobile,
                                          }}
                                          cardParent={true}
                                        />
                                        :ele?.intake_coach_name ? <ViewProfile
                                        user={{
                                          lead_photo: ele?.prog_coach_photo,
                                          lead_name: ele?.intake_coach_name,
                                          prog_lead_name: ele?.intake_coach_name,
                                          lead_activity_status:
                                            ele?.prog_lead_active == 1
                                              ? "online"
                                              : "offline",
                                          lead_email: ele?.intake_coach_email,
                                          lead_mobile: ele?.intake_coach_mobile,
                                          coach_photo: ele?.intake_coach_photo,
                                          // prog_coach_name: ele?.intake_coach_name,
                                          // coach_activity_status: ele?.intake_coach_status,
                                          // coach_email: ele?.intake_coach_email,
                                          // coach_mobile: ele?.intake_coach_mobile,
                                          supp_photo: ele?.intake_supp_photo,
                                          supp_fullname: ele?.intake_supp_name,
                                          supp_activity_status: ele?.intake_supp_status,
                                          supp_email: ele?.intake_supp_email,
                                          supp_mobile: ele?.intake_supp_mobile,
                                        }}
                                        ele={ele}
                                        cardParent={false}
                                        viewTimeTable={((ele?.fileName && ele?.file) || ele?.timeTable) ? true : false}
                                      /> : 
                                      ele?.intake_supp_name ? <ViewProfile user={{
                                        lead_photo: ele?.intake_supp_photo,
                                        prog_lead_name: ele?.intake_supp_name,
                                        lead_activity_status: ele?.intake_supp_status,
                                        lead_email: ele?.intake_supp_email,
                                        lead_mobile: ele?.intake_supp_mobile,
                                        // supp_photo: ele?.intake_supp_photo,
                                        // supp_fullname: ele?.intake_supp_name,
                                        // supp_activity_status: ele?.intake_supp_status,
                                        // supp_email: ele?.intake_supp_email,
                                        // supp_mobile: ele?.intake_supp_mobile,
                                      }}/> :
                                      null}
                                      {((ele?.fileName && ele?.file) || ele?.timeTable) && (
                                        <div className="quali_couses_ptg">
                                          <button
                                            title="Download Timetable"
                                            className="btn download_btn"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              downloadFile(ele?.file, ele?.fileName);
                                            }}
                                          >
                                            <i className="fal fa-calendar-alt"></i> View Timetable
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="qualification-box-progress">
                                      <div className="d-flex align-items-center" style={{ margin: "0px 0" }}>
                                        <p className="status" style={{ fontWeight: "600", fontSize: "10px", lineHeight: "10px", marginBottom: "0", }}>{ele?.progress.progressBar}% Complete</p>
                                        <div className="d-flex align-items-center" style={{ paddingLeft: "10px" }}>
                                          <img src={icon} alt="" width="10px" height="10px" />
                                          <p className="course-status">{ele?.subjectStatus || "NA"}</p>
                                        </div>
                                      </div>
                                      <div className="progress">
                                        <div
                                          className={"progress-bar zero-progress bar-data"}
                                          role="progressbar"
                                          title={`${ele?.progress.progressBar}%`}
                                          style={{
                                            width: (ele?.progress.progressBar || 5) + "%",
                                          }}
                                          aria-valuenow="25"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        >
                                          {ele?.progress.progressBar}%
                                        </div>
                                      </div>

                                      <div className="program-statistics-count">
                                        <ul className="num-info">
                                          {ele?.progress.total_videos > 0 && (
                                            <li title="Videos" className="videoes">
                                              <i className="fal fa-video" />
                                              <span className="value">{ele?.progress.total_videos}</span>
                                            </li>
                                          )}
                                          {ele?.progress.total_quizs > 0 && (
                                            <li title="Quizzes" className="Quizzes">
                                              <i className="fal fa-clipboard-list" />
                                              <span className="value">{ele?.progress.total_quizs}</span>
                                            </li>
                                          )}
                                          {ele?.progress.total_documents > 0 && (
                                            <li title="Files" className="files">
                                              <i className="fal fa-file" />
                                              <span className="value">{ele?.progress.total_documents}</span>
                                            </li>
                                          )}
                                          {ele?.progress.total_assessment > 0 && (
                                            <li title="Assessment" className="files">
                                              <i className="fal fa-ballot" />
                                              <span className="value">{ele?.progress.total_assessment}</span>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    ):
                      <>
                        {state?.qualifications?.map((ele, ind) => {
                          return (
                            <div className="col-lg-3 col-md-6 col-xs-12 qualification-box" key={ind} title={ele?.course}>
                              <div className="couses-renew-card home-bx-card qualification-card" onClick={handleCardClick}>
                                <Link to={`/mycourses/${ele?.id}`} className="d-flex flex-column justify-content-between h-100">
                                  <div className="d-flex flex-column">
                                    {ele?.program_type_name ? <div className="couses-card">
                                      <CommonCourseComponents degree={ele?.program_type_name} />
                                      {ele?.commentCount != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                                        <span className="nav-fa-comments card-comments">
                                          <small className="notify-count">{ele?.commentCount ? ele?.commentCount < 10 ? ele?.commentCount : "9+" : 0}</small>
                                          <i className="fal fa-comments notification-icon"></i>
                                        </span>
                                      </div>}
                                    </div> : null}

                                    <div className="program-tittle">
                                      <h2 title={ele?.course ? ele?.course : "NA"}>{ele?.course ? TrimText(ele?.course, 30) : "NA"}</h2>
                                      {!ele?.program_type_name && ele?.commentCount != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                                        <span className="nav-fa-comments card-comments">
                                          <small className="notify-count">{ele?.commentCount ? ele?.commentCount < 10 ? ele?.commentCount : "9+" : 0}</small>
                                          <i className="fal fa-comments notification-icon"></i>
                                        </span>
                                      </div>}
                                    </div>
                                    <p className="program-type" title={`${ele?.type && ele?.number && ele?.year ? `${ele?.type}, Intake ${ele?.number}, ${ele?.year}` : '-'}`}>
                                      {ele?.type && ele?.number && ele?.year ? `${ele?.type}, Intake ${ele?.number}, ${ele?.year}` : '-'}
                                    </p>
                                    <div className="d-flex align-items-center">
                                      {ele?.type == "Self-Paced" ? (
                                        <p className="course__card__duration" title={`${ele?.subject_duration ? `${ele?.subject_duration} months` : "-"}`}>
                                          <span className="couse_i_in">
                                            <i className="fal fa-clock" style={{ marginRight: "5px" }}></i>
                                          </span>
                                          {ele?.subject_duration ? `${ele?.subject_duration} months` : "-"}
                                        </p>
                                      ) : (
                                        <p className="course__card__duration" title={ele?.start && ele?.end ? formatDateRange(ele?.start, ele?.end) : "-"}>
                                          <span className="couse_i_in">
                                            <i className="fal fa-calendar-alt" style={{ marginRight: "5px" }}></i>
                                          </span>
                                          {ele?.start && ele?.end ? (
                                            <>
                                              {formatDateRange(ele?.start, ele?.end)}
                                            </>
                                          ) : "-"}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="qualification-view align-items-start">
                                      {ele?.prog_lead_name ? <ViewProfile
                                        user={{
                                          lead_photo: ele?.prog_lead_photo,
                                          prog_lead_name: ele?.prog_lead_name,
                                          lead_activity_status: ele?.prog_lead_active == 1 ? "online" : "offline",
                                          lead_email: ele?.prog_lead_email,
                                          lead_mobile: ele?.prog_lead_mobile,
                                          supp_photo: ele?.prog_supp_photo,
                                          supp_fullname: ele?.prog_supp_name,
                                          supp_activity_status: ele?.prog_supp_active == 1 ? "online" : "offline",
                                          supp_email: ele?.prog_supp_email,
                                          supp_mobile: ele?.prog_supp_mobile,
                                        }}
                                        ele={ele}
                                        cardParent={false}
                                        viewTimeTable={((ele?.fileName && ele?.file) || ele?.timeTable) ? true : false}
                                      /> : null}

                                      {((ele?.fileName && ele?.file) || ele?.timeTable) && (
                                        <div className="quali_couses_ptg">
                                          <button
                                            title="Download Timetable"
                                            className="btn download_btn"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              downloadFile(ele?.file, ele?.fileName);
                                            }}
                                          >
                                            <i className="fal fa-calendar-alt"></i> View Timetable
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="qualification-box-progress">
                                      <p className="status" style={{ fontWeight: "600", fontSize: "10px", lineHeight: "10px" }} title={`${ele?.progress?.progressBar}% Complete`}>{ele?.progress?.progressBar}% Complete</p>

                                      <div className="progress">
                                        <div
                                          title={`${ele?.progress.progressBar}%`}
                                          className={"progress-bar zero-progress bar-data"}
                                          role="progressbar"
                                          style={{
                                            width: (ele?.progress.progressBar || 5) + "%",
                                          }}
                                          aria-valuenow="25"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        >
                                          {ele?.progress.progressBar}%
                                        </div>
                                      </div>

                                      <div className="program-statistics-count">
                                        <ul className="num-info">
                                          {ele?.progress.total_videos > 0 && (
                                            <li title="Videos" className="videoes">
                                              <i className="fal fa-video" />
                                              <span className="value">{ele?.progress.total_videos}</span>
                                            </li>
                                          )}
                                          {ele?.progress.total_quizs > 0 && (
                                            <li title="Quizzes" className="Quizzes">
                                              <i className="fal fa-clipboard-list" />
                                              <span className="value">{ele?.progress.total_quizs}</span>
                                            </li>
                                          )}
                                          {ele?.progress.total_documents > 0 && (
                                            <li title="Files" className="files">
                                              <i className="fal fa-file" />
                                              <span className="value">{ele?.progress.total_documents}</span>
                                            </li>
                                          )}
                                          {ele?.progress.total_assessment > 0 && (
                                            <li title="Assessment" className="files">
                                              <i className="fal fa-ballot" />
                                              <span className="value">{ele?.progress.total_assessment}</span>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    }
                  </div>
                </div>
              </div>
    </>       
  );
}

export default memo(QualificationBox)