import React, { useEffect, useState, useMemo, useRef,useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import {getRandomInt,shortenText}  from '../../utils/commonFunction.js'
import { download } from "../../utils/FileDownload";
import ReactHtmlParser from "react-html-parser";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
  GetSubjectEvent, GetSubjectEventTzCalender
} from "../../services/CalendarService";
import {
  JoinBBBClass, 
  CampusClass,
  SaveRecordingAction,
  SaveFeedbackRating,
  GetClassFiles
} from "../../services/ClassService";
import GlobalCalendarListView from "../course/GlobalCalendarListView";
import Swal from "sweetalert2";
import $ from 'jquery';
import moment from 'moment-timezone';
import "../../assets/js/rating.js";
import SekletonGlobalCalendar from "../../loaders/SekletonGlobalCalendar";
import { getUserSettings } from "../../utils/Auth";
import { Settings } from "../../utils/CustomModels";
import ReactDOMServer from 'react-dom/server';
import SkeletonTicketList from "../../loaders/SkeletonTicketList.js";
import DataTable from "react-data-table-component";
import { RenderFeedAssessmentType, RenderFeedAssessmentStatus } from "../ticket/StatusRender.js";
import Countdown, { zeroPad } from "react-countdown";
import { twoDecimalRound } from "../../utils/commonFunction.js";
const stc = require('string-to-color');
// moment.tz.setDefault("Africa/Johannesburg");

const saveData= (blob,fileName,this_var) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  this_var.button('reset');
  Swal.close();
  Swal.fire({
   icon: "success",
   title: "Downloading Finished",
   showConfirmButton: true
 }).then((result) => {
 });
}

 const downloadVideo = (url,name,this_var) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.onload = function () {
  saveData(this.response, name,this_var); 
  };
  xhr.send();
}

$(document).on("click" , ".watch-video" , function() {
  let id = $(this).attr("class_id");
  let action="view_recording";
  SaveRecordingAction({id,action});
});

let showChar = 210;
let ellipsestext = "...";
let moretext = "Show more";
let lesstext = "Show less";

$(document).on("click" , ".morelink" , function() {
  if($(this).hasClass("less")) {
    $(this).removeClass("less");
    $(this).html(moretext);
  } else {
    $(this).addClass("less");
    $(this).html(lesstext);
  }
  $(this).parent().prev().toggle();
  $(this).prev().toggle();
  return false;
});

$(document).on("click",".download-video",function() {
  Swal.close();
  Swal.fire({
   icon: "info",
   title: "Downloading Started",
   showConfirmButton: false
 }).then((result) => {
 });
  let id = $(this).attr("class_id");
  let action="download_recording";
  let url=$(this).attr("url");
  let name=$(this).attr("name");
  let this_var=$(this);
  this_var.button('loading').queue();
  // downloadVideo(url,name,this_var);
  SaveRecordingAction({id,action});
});

$(document).on("click",".class-files",function() {
  let big_blue_button_id = $(this).attr("big_blue_button_id");
  let action="download_recording";
  let url=$(this).attr("url");
  let name=$(this).attr("name");
  let this_var=$(this);
  this_var.button('loading').queue();

  GetClassFiles({big_blue_button_id})
  .then((res) => {

    if(res.data.data.Contents.length>0){
    //   Swal.close();
    //   Swal.fire({
    //    icon: "info",
    //    title: "Download Started",
    //    showConfirmButton: false
    //  }).then((result) => {
    //  });
      res.data.data.Contents.forEach((element,index) => {
        let absolutePath='https://playback.myaie.ac/'+element.Key;
        let assArray = element.Key.split("/");
        let assArrayLen = assArray.length;
        let fileName = assArray[assArrayLen - 1];
        // let value = download(absolutePath, fileName);
        Swal.close();
        Swal.fire({
         icon: "info",
         title: index+1+" of "+res.data.data.Contents.length+" are downloading",
         showConfirmButton: false
       }).then((result) => {
       });
     });
    //  Swal.close();
    //  Swal.fire({
    //   icon: "success",
    //   title: "Downloading Finished",
    //   showConfirmButton: true
    // }).then((result) => {
    // });
    }else{

      // Swal.close();
      // Swal.fire({
      //   icon: "error",
      //   title: "No files available for download",
      //   showConfirmButton: true
      // }).then((result) => {});
    }
  })
  .catch((error) => {
    Swal.close();
    Swal.fire({
      icon: "error",
      title: "Error while downloading files",
      text: error,
    }).then((result) => {});
  });
});

const GlobalCalendar = ({subjectId, givenFilter, showFilter=true}) => {
const location = useHistory();
const [countprev, setprevcount] = useState(1);
const [countnext, setnextcount] = useState(1);
let sD = moment().startOf('week');
let eD = moment().endOf('week');
const [startDateListView, setStartDateListView] = useState(sD);
const [endDateListView, setEndDateListView] = useState(eD);
const [showMonthWeekDate, setShowMonthWeekDate] = useState(false);
const [popupUpdate, setPopupUpdate] = useState(false);
const [workWeek, setWorkWeek] = useState(false);
const [classes, setClasses] = useState([]);
const [tempClasses, setTempClasses] = useState([]);
const [todayCalendar, setTodayCalendar] = useState(true);
const [filter, setFilter] = useState(givenFilter);
const [showCalendarLoading, setShowCalendarLoading] = useState(false);
let view='week';
if(Settings.hasOwnProperty("calendar")){
  if(Settings.calendar.defaultView=='list'){
    view='upcoming';
  }else{
    view='week';
  }
}
let userSettings=getUserSettings();
 if(userSettings!==undefined && userSettings!==null){    
   if(Object.keys(userSettings).length!==0){ 
    userSettings=JSON.parse(userSettings);
    if(userSettings.hasOwnProperty("calendar")){
      if(userSettings.calendar.defaultView=='list'){
        view='upcoming';
      }else{
        view='week';
      }
    }
  }
 }
const [calendarView, setCalendarView] = useState(view);
let calendarRef = useRef(null);
$(document).off('click', '#expandDiv').on('click', '#expandDiv', function() {
  
  let thisBody = document.getElementById("breakoutTableDiv");
  let expandIcon = document.querySelector('.expand-icon i'); // Select the chevron icon

  if (thisBody.style.visibility !== "hidden") {
    thisBody.style.visibility = "hidden";
    thisBody.style.height = "0";
    expandIcon.classList.remove('rotate'); // Remove rotation when collapsed
  } else {
    thisBody.style.visibility = "visible";
    thisBody.style.height = "auto"; // Expand to its full height
    expandIcon.classList.add('rotate'); // Add rotation when expanded
  }
});

const goToSubject= (qid,sid)=>{
  location.push(`/course/${qid}/subject/${sid}`);
}
const joinVirtualClassGlobal= (id)=>{
  JoinBBBClass({id})
  .then((res) => {
   if(res.data.error==0){
     Swal.close();
     Swal.fire({
      icon: "success",
      title: res.data.title,
      text:res.message,
      showConfirmButton: true
    }).then((result) => {
      if(result.isConfirmed){
        window.open(res.data.url, '_blank');
        let item = res.data.class;
        var foundIndex = tempClasses.findIndex(x => x.id == item.id);
        tempClasses[foundIndex] = item;
        setPopupUpdate(id+getRandomInt(1));
      }
    });
   }else{
     Swal.close();
     Swal.fire({
      icon: "error",
      title: res.data.title,
      text:res.data.message,
      showConfirmButton: true
    })
   }
  })
  .catch((error) => {
    Swal.fire({
      icon: "error",
      title: "Unknown Error.",
    }).then((result) => {});
  });
}

const joinCampusClassGlobal= (id)=>{
  let action="enter";
  CampusClass({id,action})
  .then((res) => {
   if(res.data.error==0){
     Swal.close();
     Swal.fire({
      icon: "success",
      title: res.data.title,
      text:res.data.message,
      showConfirmButton: true
    }).then((result) => {
      if(result.isConfirmed){
        let item = res.data.class;
        var foundIndex = tempClasses.findIndex(x => x.id == item.id);
        tempClasses[foundIndex] = item;
        setPopupUpdate(id+getRandomInt(2));
      }
    });
   }else{
     Swal.close();
     Swal.fire({
      icon: "error",
      title: res.data.title,
      text:res.data.message,
      showConfirmButton: true
    })
   }
  })
  .catch((error) => {
    Swal.fire({
      icon: "error",
      title: "Unknown Error.",
    }).then((result) => {});
  });
}

const exitCampusClassGlobal= (id)=>{
  let action="exit";
  CampusClass({id,action})
  .then((res) => {
   if(res.data.error==0){
     Swal.close();
     Swal.fire({
      icon: "success",
      title: res.data.title,
      text:res.data.message,
      showConfirmButton: true
    }).then((result) => {
      if(result.isConfirmed){
        let item = res.data.class;
        var foundIndex = tempClasses.findIndex(x => x.id == item.id);
        tempClasses[foundIndex] = item;
        setPopupUpdate(id+getRandomInt(3));
      }
    });
   }else{
     Swal.close();
     Swal.fire({
      icon: "error",
      title: res.data.title,
      text:res.data.message,
      showConfirmButton: true
    })
   }
  })
  .catch((error) => {
    Swal.fire({
      icon: "error",
      title: "Unknown Error.",
    }).then((result) => {});
  });
}

const submitRatingGlobal= (id)=>{
  let student_rating= $("#class_rating_"+id).val();
  let student_comment= $("#class_comment_"+id).val();
  SaveFeedbackRating({student_rating,student_comment,id})
  .then((res) => {
    if(res.data.error==0){
      Swal.close();
      Swal.fire({
       icon: "success",
       title: res.data.title,
       text:res.data.message,
       showConfirmButton: true
     }).then((result) => {
       if(result.isConfirmed){
         let item = res.data.class;
         var foundIndex = tempClasses.findIndex(x => x.id == item.id);
         tempClasses[foundIndex] = item;
         setPopupUpdate(getRandomInt(4));
        }
     });
    }else{
      Swal.close();
      Swal.fire({
       icon: "error",
       title: res.data.title,
       text:res.data.message,
       showConfirmButton: true
     })
    }
   })
   .catch((error) => {
      Swal.fire({
       icon: "error",
       title: "Unknown Error.",
      }).then((result) => {});
   });
}
useEffect(() => {
  $(document).ready(function(){
    $(document).off('click', '.join-virtual-classes-calendar').on('click', '.join-virtual-classes-calendar', function(e){
      e.preventDefault();
      var id = $(this).attr('data-id');
      joinVirtualClassGlobal(id);
    });
    $(document).off('click', '.join-campus-classes-calendar').on('click', '.join-campus-classes-calendar', function(e){
      e.preventDefault();
      var id = $(this).attr('data-id');
      joinCampusClassGlobal(id);
    });
    $(document).off('click', '.exit-campus-classes-calendar').on('click', '.exit-campus-classes-calendar', function(e){
      e.preventDefault();
      var id = $(this).attr('data-id');
      exitCampusClassGlobal(id);
    });
    $(document).off('click', '.submit-rating-global-calendar').on('click', '.submit-rating-global-calendar', function(e){
      e.preventDefault();
      var id = $(this).attr('data-id');
      submitRatingGlobal(id);
    });
    $(document).off('click', "#go-to-subject").on('click', "#go-to-subject", function(e){
      e.preventDefault();
      var qualification = $(this).attr("data-qid");
      var subject = $(this).attr("data-sid");
      goToSubject(qualification, subject)
    })
  });
},[])

const RenderMarkingCountdown = (marking_deadline) =>{    
  let countTimer =  Date.now() + (Date.parse(moment.unix(marking_deadline).format("DD MMM YYYY HH:mm a")) - Date.now());
  if (Date.now() > countTimer) {
    countTimer = 0;
  }
  const Renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>{zeroPad(0)}d:{zeroPad(0)}h:{zeroPad(0)}m</span>
    } else {
      //Render a countdown
      return <span>{zeroPad(days)}d:{zeroPad(hours)}h:{zeroPad(minutes)}m</span>
            
    }
  };

  return(
    countTimer ?
    <Countdown
      date={countTimer}
      renderer={Renderer}
      zeroPadTime='2'
    >
    </Countdown>
    : <Renderer completed={true} />
  )
}

const RenderAssesmentPopup = (row) =>{
  return (
    <div class="meeting-detail-box past-meeting-detail-box">
          <span class="lib-con">
            {RenderFeedAssessmentType(row?.ass_type).html}
          </span>
          {RenderFeedAssessmentStatus(row?.status).html}

          { row?.ass_type != "Survey" ?(row?.status=="Open" ?
                                <span className="assessment-conditional-detail">
                                  <i className="fal fa-clock"></i> {`${row?.timeHour.toString().padStart(2,'0')}h:${row?.timeMin.toString().padStart(2,'0')}m`}  
                                  &ensp;
                                  <i className="fal fa-calendar-exclamation item-text-red"></i> {`${moment.unix(row.close_date_time).format("dddd, DD MMM YY, HH:mm")}`}                               
                                </span>
                                : (row?.status == "Closed-Submitted" || row?.status=="Submitted") ? (row?.assCalculateGradeStatus?.gradeText == 'witheld' || (row?.grademixCulc?.length > 0 && row?.grademixCulc[0].gradeText == 'witheld')) ? 
                                (<><span className="assessment-conditional-detail"><i className="fal fa-ballot-check"></i> Result:</span>
                                  <span 
                                    title={'Your marks are being withheld due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket'} 
                                    className={`as-red-bg as-widget`}>
                                    <i className={`fal fa-ban`}></i> {"WITHHELD"}
                                  </span></>)
                                :(<span className="assessment-conditional-detail">
                                <i class="fal fa-calendar-edit"></i> {RenderMarkingCountdown(row?.marking_deadline_date)}
                                </span>)                                   
                                :(row?.status=="Closed-Marked" || row?.status=="Closed Not-Submitted") && row?.ass_type!="Survey" ?                                   
                                (row?.assCalculateGradeStatus?.gradeText == 'witheld' || (row?.grademixCulc?.length > 0 && row?.grademixCulc[0].gradeText == 'witheld')) ? 
                                (<><span className="assessment-conditional-detail"><i className="fal fa-ballot-check"></i> Result:</span>
                                  <span 
                                    title={'Your marks are being withheld due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket'} 
                                    className={`as-red-bg as-widget`}>
                                    <i className={`fal fa-ban`}></i> {"WITHHELD"}
                                  </span></>)
                                : (!(row?.assCalculateGradeStatus) || row?.grademixCulc?.length > 0) ? (
                                  <>
                                  <span className="assessment-conditional-detail"><i className="fal fa-ballot-check"></i> Result:</span>
                                  <span 
                                  title={"Open assessment to view your result"}
                                    className={`as-green-bg as-widget`}>
                                    <i className={`fal fa-file-check`}>
                                    </i> {"Available"}
                                  </span>
                                  </>
                                )
                                : (<><span className="assessment-conditional-detail">
                                  <i className="fal fa-ballot-check"></i> Result: 
                                  <span className="font-weight-bold">
                                    {row?.grading_type === 3 ? 
                                      ` ${twoDecimalRound(row?.assCalculateGradeStatus?.gradeText)}/${row?.subAssTotMark}` 
                                      : row?.grading_type === 0 ? 
                                      ` ${twoDecimalRound(row?.assCalculateGradeStatus?.grade)}%` 
                                      : ` ${(row?.assCalculateGradeStatus?.gradeText)}`
                                    }
                                  </span>
                                </span></>)
                                :null)
                                :null}
          
          
          <h5 className="assessment-title-popup">{row.name}</h5>
          <ul class="fa-ul meeting-detail-list-popup">
          <li><i class="fal fa-calendar-alt"></i><span class="f-bold"> Open: </span>{moment.unix(row.open_date_time).format("dddd, D MMM YY")}, {moment.unix(row.open_date_time).format("h:mm A")}</li>
          <li><i class="fal fa-calendar-alt"></i><span class="f-bold"> Close: </span>{moment.unix(row.close_date_time).format("dddd, D MMM YY")}, {moment.unix(row.close_date_time).format("h:mm A")}</li>
          </ul> 
          <hr/>
      {(row.camera || row.audio || row.screen_share || row.timeLimitActive) ?
        <div className="assessment-condition-container">
          <span>
            Conditions: {row.camera ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational webcam" className="fal fa-webcam active"></i> : null}
            {row.audio ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational microphone" className="fal fa-microphone"></i> : null}
            {row.screen_share ? <i data-toggle="tooltip" title="This exam will record the contents of your screen, please have one screen and disconnect secondary screens" className="fal fa-desktop"></i> : null}
            {row.timeLimitActive ? <i data-toggle="tooltip" title="This is a timed exam" className="fal fa-clock"></i> : null}
          </span>
        </div>
        : null}
      <div className="class-action feed-assessment-action">
        <a
          className="btn btn-primary btn-assessment-popup"
          target="_blank"
          href={`/course/${row?.qualificationId}/subject/${row?.intake}/assessment/${row?.id}`}
        >
          <i className="fal fa-file-alt"></i> Open Assessment
        </a>
      </div>
    </div>
  )
}

const showBreakoutRooms= (model)=>{
  
    return <div className=" breakoutTable custom-table-div filter-search-icon card-table-custom days-overdue-cell-width" style={{padding: "0px"}}>
    <DataTable
      progressComponent={<SkeletonTicketList />}
      data={model.raw.breakoutRooms}
      columns={breakoutColumns}
      defaultSortAsc={true}
      // defaultSortField="class_title"
      highlightOnHover={false}
    />
  </div>
}

const breakoutColumns = useMemo(() => [
  {
    name: "Room Title",
    selector: "class_title",
    // sortField: "title",
    // sortable: true,
    cell: (row) => (
      <>
        <span
          className="overflow-ellipsis2 fst_line2 feature-name fist-name-box"
          title={row.class_title}
        >
          <span
            data-toggle="modal"
            data-target="#launch"
            className="as-text-blue curser">
            <span className="textLimit100">{row.class_title ? row.class_title : "-"} </span>
          </span>
          {row.isRecurring == 1 && row.isRecurringDifferent == 1 ? (
            <div className="icon-w-text">
              <span className="icon-w">
                <i className="fal fa-refresh not-refresh"></i>
              </span>
            </div>
          ) : row.isRecurring == 1 && (
            <div className="icon-w-text">
              {" "}
              <span className="icon-w">
                <i className="fal fa-refresh"></i>
              </span>
            </div>
          )}
        </span>
      </>
    ),
  },
    
  {
    name: "Recordings",
    selector: "",
    sortable: false,
    cell: (row) => {
      
      return (<div className="d-flex flex-column flex-md-row gap-2">
        {row.IsRecorded === 1 && row.viewURL ? (
            <>
                <a
                    href={row.viewURL}
                    target="_blank"
                    className="btn btn-primary watch-video-btn"
                    title="Watch Video"
                >
                    <i className="fal fa-play"></i> <span className="d-noneOnSmall">Watch Video</span>
                </a>
                &ensp;

            </>
        ) : (
            <>
                {row.IsRecorded === 1 && row.ViewAvailable == null ? (
                    <>
                        <i className="fal fa-eye"></i>
                        <span className="pl-1">Processing</span>
                    </>
                ) : (
                    <>
                        <i className="fal fa-times align-content-center"></i> &nbsp;Not Recorded
                    </>
                )}
            </>
        )}

        {row.IsRecorded === 1 && row.downloadURL ? (
            <>
                <a
                    href={row.downloadURL}
                    target="_blank"
                    className="btn btn-primary mt-2 mt-xl-0 download-video"
                    title="Download"
                >
                    <i className="fal fa-download"></i> <span className="d-noneOnSmall">Download</span>
                </a>
            </>
        ) : (
            <>
                {row.IsRecorded === 1 && row.DownloadAvailable == null ? (
                    <>
                        <i className="fal fa-download"></i>
                        <span className="pl-1">Processing</span>
                    </>
                ) : (
                    <></>
                )}
            </>
        )}
    </div>)
    }      
      
  },
], []);

var COMMON_CUSTOM_THEME = {
    'common.holiday.color': '#bbb',
    'month.holidayExceptThisMonth.color': '#bbb',
    'week.today.color': '#467fcf',
    //'month.schedule.height': '90px',
    //'week.dayGridSchedule.height': '24px',
    //'week.vpanelSplitter.height': '20px',
    //'week.dayGridSchedule.height': '40px',

    // month schedule style
    //'month.schedule.borderRadius': '0px',
    //'month.schedule.height': '90px',
    'month.schedule.top': '0px',
    'month.schedule.marginTop': '0px',
    'month.schedule.marginLeft': '0px',
    'month.schedule.marginRight': '0px',
    'week.timegridOneHour.height': '45px',
    //'week.timegridHalfHour.height': '45px',
    
    // month more view
    // 'month.moreView.border': '1px solid #d5d5d5',
    // 'month.moreView.boxShadow': '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
    // 'month.moreView.backgroundColor': 'white',
    //'month.moreView.paddingBottom': '0px',
    //'month.moreViewTitle.height': '44px',
    //'month.moreViewTitle.marginBottom': '0px',
    // 'month.moreViewTitle.backgroundColor': 'inherit',
    // 'month.moreViewTitle.borderBottom': 'none',
    //'month.moreViewTitle.padding': '0px 0px 0 0px',
    //'month.moreViewList.padding': '0 0px',

};

const calendarOptions = {
  disableDblClick : true,
  disableClick : true,
  isReadOnly : true,
  taskView: ['task'],
  //taskView: ['task','milestone'],
  scheduleView:['time'],
  //scheduleView:true,
  month: {
    daynames: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
  },
  week: {
    daynames: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
  },
  theme: COMMON_CUSTOM_THEME, 
  template : {
    milestone: function(schedule) {
      return '<span class="calendar-font-icon ic-milestone-b"></span> <span style="background-color: ' + schedule.bgColor + '">' + schedule.title + '</span>';
    },
    milestoneTitle: function() {
        return '<span class="tui-full-calendar-left-content">Important Dates/Events</span>';
    },
    task: function(schedule) {
      let color= stc(schedule.raw.subjectName);
      return `<div class="assessment-title-box">
       <h6 class="assessment-title">${shortenText(schedule.title?.trim(),40)}
       </h6>
       <div class="status"><i class="fas fa-circle" title="${schedule.raw.subjectName}" style="color:${color} !important"></i></div>
       </div>`;

        return schedule.title;
    },
    taskTitle: function() {
        return '<span class="tui-full-calendar-left-content">Assessments</span>';
    },
    // allday: function(schedule) {
    //     return getTimeTemplate(schedule, true);
    // },
    // alldayTitle: function() {
    //     return '<span class="tui-full-calendar-left-content">ALL DAY</span>';
    // },
    time: function(schedule) {
      let currentTime = moment().unix();
      let sst = moment(schedule.raw.class_from, ["h:mm A"]).format("HH:mm:ss");
      let eet = moment(schedule.raw.class_to, ["h:mm A"]).format("HH:mm:ss");
      let st = moment(schedule.raw.class_date + " " + sst);
      let et = moment(schedule.raw.class_date + " " + eet);
      let start_time = st.unix();
      let end_time = et.unix();
      let start_time_live = moment(st).subtract(15,'minutes').unix();

      let color= stc(schedule.raw.subjectName);
      if(schedule.raw.canceled || (schedule.raw.started_at == 0 && schedule.raw.class_status == "Past")){
        return `<div class="meeting-title-box canceled-meeting-title-box">
         <h6 class="meeting-title">${shortenText(schedule.title?.trim(),10)}
          ${schedule.raw.class_type=='hybrid' ? (
            `<span class="d-inline-block"><i class="fal fa-university"></i>
            <i class="fal fa-house-signal"></i></span>`
          ): schedule.raw.class_type=='on_campus' ? (
            `<i class="fal fa-university"></i>`
          )                                                           
          : (
            `<i class="fal fa-house-signal"></i>`
          )}
          </h6>
          <div class="text-overflow-ellipsis">${schedule?.raw?.class_description ? shortenText(schedule.raw.class_description?.trim(),25) : ""}</div>
          ${schedule.raw.recurring ? ('<div class="meeting-title-reload"><i class="fal fa-repeat"></i></div>'):('')}
          <div class="status"><i class="fas fa-circle" title="${schedule.raw.subjectName}" style="color:${color} !important"></i></div>
          </div>`;
      }else if(end_time < currentTime){
        return `<div class="meeting-title-box past-meeting-title-box">
        <h6 class="meeting-title">${shortenText(schedule.title?.trim(),40)}
        ${schedule.raw.class_type=='hybrid' ? (
         `<span class="d-inline-block"><i class="fal fa-university"></i>
          <i class="fal fa-house-signal"></i></span>`
        ): schedule.raw.class_type=='on_campus' ? (
          `<i class="fal fa-university"></i>`
        )                                                           
        : (
          `<i class="fal fa-house-signal"></i>`
        )}
        </h6>
        <div class="text-overflow-ellipsis">${schedule?.raw?.class_description ? shortenText(schedule.raw.class_description?.trim(),25) : ""}</div>
        ${cardRating(schedule.raw.percentage,schedule.raw.student_rating,schedule.raw.student_comment)}
        ${schedule.raw.recurring ? ('<div class="meeting-title-reload"><i class="fal fa-repeat"></i></div>'):('')}
        <div class="status"><i class="fas fa-circle" title="${schedule.raw.subjectName}" style="color:${color} !important"></i></div>
        </div>`;
      }else if(start_time_live <= currentTime && end_time >= currentTime){
        return `<div class="meeting-title-box live-meeting-title-box">
        <h6 class="meeting-title">${shortenText(schedule.title?.trim(),40)}
        ${schedule.raw.class_type=='hybrid' ? (
          `<span class="d-inline-block"><i class="fal fa-university"></i>
          <i class="fal fa-house-signal"></i></span>`
        ): schedule.raw.class_type=='on_campus' ? (
          `<i class="fal fa-university"></i>`
        ):(
         `<i class="fal fa-house-signal"></i>`
        )}
        </h6>
        <div class="text-overflow-ellipsis">${schedule?.raw?.class_description ? shortenText(schedule.raw.class_description?.trim(),25) : ""}</div>
        <button title="Join" class="btn btn-success btn-join-title-box"><i class="fal fa-wifi"></i>Join</button>
        ${schedule.raw.recurring ? ('<div class="meeting-title-reload"><i class="fal fa-repeat"></i></div>'):('')}
        <div class="status"><i class="fas fa-circle" title="${schedule.raw.subjectName}" style="color:${color} !important"></i></div>
        </div>`;
      }else if(start_time > currentTime){
        return `<div class="meeting-title-box upcoming-meeting-title-box">
        <h6 class="meeting-title">${shortenText(schedule.title?.trim(),40)} 
        ${schedule.raw.class_type=='hybrid' ? (
          `<span class="d-inline-block"><i class="fal fa-university"></i>
          <i class="fal fa-house-signal"></i></span>`
        ): schedule.raw.class_type=='on_campus' ? (
          `<i class="fal fa-university"></i>`
        )                                                           
        :(
          `<i class="fal fa-house-signal"></i>`
        )}
        </h6>
        <div class="text-overflow-ellipsis">${schedule?.raw?.class_description ? shortenText(schedule.raw.class_description?.trim(),25) : ""}</div>
        ${schedule.raw.recurring ? ('<div class="meeting-title-reload"><i class="fal fa-repeat"></i></div>'):('')}
        <div class="status"><i class="fas fa-circle" title="${schedule.raw.subjectName}" style="color:${color} !important"></i></div>
        </div>`;
      }
    },

    popupDetailBody:function(model){
      let currentTime = moment().unix();
      let taskContent = ""
      if(model.raw.category=='task'){
        taskContent = ReactDOMServer.renderToStaticMarkup(RenderAssesmentPopup(model.raw))
        return `${taskContent}`;
      }
      else if (model.raw.category=='time'){
        let sst = moment(model.raw.class_from, ["h:mm A"]).format("HH:mm:ss");
        let eet = moment(model.raw.class_to, ["h:mm A"]).format("HH:mm:ss");
        let st = moment(model.raw.class_date + " " + sst);
        let et = moment(model.raw.class_date + " " + eet);
        let start_time = st.unix();
        let end_time = et.unix();
        let start_time_live = moment(st).subtract(15,'minutes').unix();

      if(model.raw.canceled || (model.raw.started_at == 0 && model.raw.class_status == "Past")){
        return `<div class="meeting-detail-box past-meeting-detail-box">
          ${model.raw.class_type=='hybrid' ? ( 
            `<label class="label label-orange">
            <i class="fal fa-university"></i>
            <i class="fal fa-plus"></i>
            <i class="fal fa-house-signal"></i>
            Hybrid
            </label> `
          )
          : model.raw.class_type=='on_campus' ? (
            `<label class="label label-purple">
            <i class="fal fa-university"></i>
            On Campus
            </label> `
          )                                                           
          : (`<label class="label label-green">
            <i class="fal fa-house-signal"></i>
            Virtual
            </label>`)}            
            <small class="as-widget-class-status as-red-bg-status-cancelled" title="Cancelled" style="font-size: 12px;">
            Cancelled </small>   
            <div>         
            <h6>${model.title}</h6>
            </div>
            <ul class="fa-ul meeting-detail-list">
              <li class="class-canceled-row-td"><i class="fal fa-li fa-calendar-alt "></i>${model.raw.showDate}, ${model.raw.class_from} - ${model.raw.class_to}</li>
              ${model.raw.campus_name_room}
            </ul>
            `
           
      }else if(end_time < currentTime){
        let recordingbutton = ""
        {model.raw.recordingURL!=null? recordingbutton =`<li><a target="_blank" href= ${model.raw.recordingURL} class="btn btn-primary " ><i class="fal fa-play"></i> Watch Video</a></li>`: recordingbutton =""}
        let downloadButton = ""
        {model.raw.downloadURL!=null? downloadButton =`<li><a  target="_blank" href= ${model.raw.downloadURL} class="btn btn-primary " ><i class="fal fa-download"></i> Download Video</a></li>`:downloadButton =""}
        let breakoutTable = ""
        {model.raw.breakoutRooms.length>0? breakoutTable = ReactDOMServer.renderToStaticMarkup(showBreakoutRooms(model)) : breakoutTable = ""}
        return `<div class="meeting-detail-box past-meeting-detail-box">
          ${model.raw.class_type=='hybrid' ? ( 
            `<label class="label label-orange">
            <i class="fal fa-university"></i>
            <i class="fal fa-plus"></i>
            <i class="fal fa-house-signal"></i>
            Hybrid
            </label> `
          )
          : model.raw.class_type=='on_campus' ? (
            `<label class="label label-purple">
            <i class="fal fa-university"></i>
            On Campus
            </label> `
          )                                                           
          : (`<label class="label label-green">
            <i class="fal fa-house-signal"></i>
            Virtual
            </label>`)}
            
            <h6>${model.title}</h6>
            <ul class="fa-ul meeting-detail-list">
              <li><i class="fal fa-li fa-calendar-alt"></i>${model.raw.showDate}, ${model.raw.class_from} - ${model.raw.class_to}</li>
              ${model.raw.campus_name_room}
            </ul> 
            <hr/>
            <div class="row">
            ${model.raw.percentage>="1" ? (`
            <div class="col-12 meeting-info-status-col">
                  <span class="green-color">Present 
                  ${model.raw.join_type=='campus' ? (`
                  (on Campus)`):(`(Online)`)
                  }
                  </span>    
                  <span>${model.raw.duration} (${model.raw.percentage}%)</span>    
                  <span>I: ${model.raw.student_time_in}  O: ${model.raw.student_time_out}</span>
                </div>
                ${model.body}
                `):
                (`<div class="col-12 meeting-info-status-col">
                  <span class="red-color">Absent</span>
                  <span>${model.raw.duration} (${model.raw.percentage}%)</span>   
                  <span>I: ${model.raw.student_time_in}  O: ${model.raw.student_time_out}</span>
                </div>`)}
              ${(model.raw.recordings!='' && model.raw.recordings!=null) || model.raw.recordingURL!=null || model.raw.downloadURL!=null ? (`
              <div class="col-12 meeting-btns-col">
              <hr/>
                <div class="meeting-btns">
                   <ul class="meeting-btns-list">
                   ${model.raw.recordings} &ensp; ${recordingbutton} &ensp; ${downloadButton}
                  </ul>
                </div>
              </div>`):(``)
              }
            </div>
            ${model.raw.breakoutRooms.length>0? 
              ` <hr/>
                <div id="expandDiv">
                <div class="breakout-expand"><i class="fal fa-video"></i>&nbsp;&nbsp;Breakout Room Recordings</div> 
                <div class="expand-icon"> <b><i class="fas fa-solid fa-chevron-down"></i></b></div>
                </div>
                <div id="breakoutTableDiv" style="  visibility: hidden; height: 0;">
                ${breakoutTable}
                </div>`
              :""}
            ${model?.raw?.class_description ? (`
            <hr/>
            <div class="meeting-desc more">
            ${model.raw.class_description?.trim()}
            </div>`):('')}  
            ${model.raw.Firstname!==null ?(`
            <div class="meeting-instructor">
            <hr/>
            <p class="instructor-name"><i class="fal fa-chalkboard-teacher"></i>${model.raw.Firstname} ${model.raw.Lastname}</p>
            </div>`):('')}  
          </div>`;

      }else if(start_time_live <= currentTime && end_time >= currentTime){
        return `<div class="meeting-detail-box upcoming-meeting-detail-box">
          ${model.raw.class_type=='hybrid' ? ( 
            `<label class="label label-orange">
            <i class="fal fa-university"></i>
            <i class="fal fa-plus"></i>
            <i class="fal fa-house-signal"></i>
            Hybrid
            </label>  
            <h6>${model.title}</h6>
            <ul class="fa-ul meeting-detail-list">
              <li><i class="fal fa-li fa-calendar-alt"></i>${model.raw.showDate}, ${model.raw.class_from} - ${model.raw.class_to}</li>
              ${model.raw.campus_name_room}
              </ul> 
            <hr/>
            <div class="btn-square">
            <button data-id="${model.raw.id}" title="Join the class virtually if you are not on campus." ${model.raw.disable_online} class="btn btn-primary join-virtual-class join-virtual-classes-calendar  ${model.raw.disable_online}">
              <i class="fal fa-house-signal"></i>
              <span class="btn-text">Join Virtual Class</span>
            </button>
            <button data-id="${model.raw.id}" title="Check in here if you are attending the class on campus." ${model.raw.disable_campus} ${model.raw.hide_checkin} class="btn btn-primary join-campus-class join-campus-classes-calendar ${model.raw.disable_campus}">
             <i class="fal fa-university"></i>
              <span class="btn-text">Check In On Campus</span>
            </button>
            <button data-id="${model.raw.id}" title="On Campus Check Out" ${model.raw.hide_checkout} class="btn exit-campus-class exit-campus-classes-calendar">
            <i class="fal fa-window-close" aria-hidden="true"></i>
             <span class="btn-text">On Campus Check Out</span>
            </button>
            </div>`
          )
          : model.raw.class_type=='on_campus' ? (
            `<label class="label label-purple">
            <i class="fal fa-university"></i>
            On Campus
            </label>  
            <h6>${model.title}</h6>
            <ul class="fa-ul meeting-detail-list">
              <li><i class="fal fa-li fa-calendar-alt"></i>${model.raw.showDate}, ${model.raw.class_from} - ${model.raw.class_to}</li>
              ${model.raw.campus_name_room}
            </ul> 
            <hr/>
            <div class="btn-square">
            <button data-id="${model.raw.id}" title="Check in here if you are attending the class on campus." ${model.raw.hide_checkin} class="btn btn-primary join-campus-class join-campus-classes-calendar">
             <i class="fal fa-university"></i>
              <span class="btn-text">Check In On Campus</span>
            </button>
            <button data-id="${model.raw.id}" title="On Campus Check Out" ${model.raw.hide_checkout} class="btn exit-campus-class exit-campus-classes-calendar">
            <i class="fal fa-window-close" aria-hidden="true"></i>
             <span class="btn-text">On Campus Check Out</span>
            </button>
            </div>`
          )                                                           
          : (`<label class="label label-green">
          <i class="fal fa-house-signal"></i>
          Virtual
          </label>  
          <h6>${model.title}</h6>
          <ul class="fa-ul meeting-detail-list">
          <li><i class="fal fa-li fa-calendar-alt"></i>${model.raw.showDate}, ${model.raw.class_from} - ${model.raw.class_to}</li>
          </ul> 
          <hr/>
          <div class="btn-square">
          <button data-id="${model.raw.id}" title="Join the class virtually if you are not on campus." class="btn btn-primary join-virtual-class join-virtual-classes-calendar ">
          <i class="fal fa-house-signal"></i>
          <span class="btn-text">Join Virtual Class</span>
          </button>
          </div>`)
          }
          ${model?.raw?.class_description ? (`
          <hr/>
          <div class="meeting-desc more">
          ${model.raw.class_description?.trim()}
          </div>`):('')}  
          ${model.raw.Firstname!==null ?(`
          <div class="meeting-instructor">
          <hr/>
          <p class="instructor-name"><i class="fal fa-chalkboard-teacher"></i>${model.raw.Firstname} ${model.raw.Lastname}</p>
          </div>`):('')}   
          </div>`;
      }else if(start_time > currentTime){
        return `<div class="meeting-detail-box live-meeting-detail-box">
        ${model.raw.class_type=='hybrid' ? ( 
          `<label class="label label-orange">
          <i class="fal fa-university"></i>
          <i class="fal fa-plus"></i>
          <i class="fal fa-house-signal"></i>
          Hybrid
          </label>  
          <h6>${model.title}</h6>
          <ul class="fa-ul meeting-detail-list">
            <li><i class="fal fa-li fa-calendar-alt"></i>${model.raw.showDate}, ${model.raw.class_from} - ${model.raw.class_to}</li>
            ${model.raw.campus_name_room}
            </ul> 
          <hr/>
          <div class="btn-square">
          <button disabled data-id="${model.raw.id}" title="Join the class virtually if you are not on campus." ${model.raw.disable_online} class="btn btn-primary join-virtual-class join-virtual-classes-calendar  ${model.raw.disable_online}">
            <i class="fal fa-house-signal"></i>
            <span class="btn-text">Join Virtual Class</span>
          </button>
          <button disabled data-id="${model.raw.id}" title="Check in here if you are attending the class on campus." ${model.raw.disable_campus} ${model.raw.hide_checkin} class="btn btn-primary join-campus-class join-campus-classes-calendar ${model.raw.disable_campus}">
           <i class="fal fa-university"></i>
            <span class="btn-text">Check In On Campus</span>
          </button>
          <button data-id="${model.raw.id}" title="On Campus Check Out" ${model.raw.hide_checkout} class="btn exit-campus-class exit-campus-classes-calendar">
          <i class="fal fa-window-close" aria-hidden="true"></i>
           <span class="btn-text">On Campus Check Out</span>
          </button>
          </div>`
        )
        : model.raw.class_type=='on_campus' ? (
          `<label class="label label-purple">
          <i class="fal fa-university"></i>
          On Campus
          </label>  
          <h6>${model.title}</h6>
          <ul class="fa-ul meeting-detail-list">
            <li><i class="fal fa-li fa-calendar-alt"></i>${model.raw.showDate}, ${model.raw.class_from} - ${model.raw.class_to}</li>
            ${model.raw.campus_name_room}
          </ul> 
          <hr/>
          <div class="btn-square">
          <button disabled data-id="${model.raw.id}" title="Check in here if you are attending the class on campus." ${model.raw.hide_checkin} class="btn btn-primary join-campus-class join-campus-classes-calendar">
           <i class="fal fa-university"></i>
            <span class="btn-text">Check In On Campus</span>
          </button>
          <button data-id="${model.raw.id}" title="On Campus Check Out" ${model.raw.hide_checkout} class="btn exit-campus-class exit-campus-classes-calendar">
          <i class="fal fa-window-close" aria-hidden="true"></i>
           <span class="btn-text">On Campus Check Out</span>
          </button>
          </div>`
        )                                                           
        : (`<label class="label label-green">
        <i class="fal fa-house-signal"></i>
        Virtual
        </label>  
        <h6>${model.title}</h6>
        <ul class="fa-ul meeting-detail-list">
        <li><i class="fal fa-li fa-calendar-alt"></i>${model.raw.showDate}, ${model.raw.class_from} - ${model.raw.class_to}</li>
        </ul> 
        <hr/>
        <div class="btn-square">
        <button disabled data-id="${model.raw.id}" title="Join the class virtually if you are not on campus." class="btn btn-primary join-virtual-class join-virtual-classes-calendar ">
        <i class="fal fa-house-signal"></i>
        <span class="btn-text">Join Virtual Class</span>
        </button>
        </div>`)
        }
        ${model?.raw?.class_description ? (`
        <hr/>
        <div class="meeting-desc more">
        ${model.raw.class_description?.trim()}
        </div>`):('')}  
        ${model.raw.Firstname!==null ?(`
        <div class="meeting-instructor">
        <hr/>
        <p class="instructor-name"><i class="fal fa-chalkboard-teacher"></i>${model.raw.Firstname} ${model.raw.Lastname}</p>
        </div>`):('')}   
        </div>`;
      }
    }
    }
  },
}
const cardRating=(percentage,val,student_comment)=> {
  if(percentage>="1"){
    let returnValue='';
    let remainingRating=5-val;
    let comment='';
    let classInline='';
    if(val>0){
      classInline='text-overflow-ellipsis';
    }
    returnValue=`<div class="meeting-rating-card mt-1 ${classInline}">
    <span class="stars">`;
     if(val>0){
       for (let index = 1; index <= val; index++) {
         returnValue +=`<i class="fas fa-star"></i>`;
       }
       comment=student_comment?.trim();
     }else{
      comment='Rate this class';
     }
     for (let index = 1; index <= remainingRating; index++) {
       returnValue +=`<i class="fal fa-star"></i>`;
     }
   returnValue+=`</span>`;
   returnValue+=`<span class="ml-2">${comment}</span> </div>`;
   return returnValue;
  }else{
    return ` `;

  }
}
const printStar=(id,val,student_comment,percentage)=> {
  let returnValue=` `;
  let remainingRating=5-val;
  if(val>0){
     returnValue=`<div class="col-12 meeting-rating-start-col m-0">
     <div class="meeting-rating-start d-flex ">
     <span class="stars">`;
      for (let index = 1; index <= val; index++) {
        returnValue +=`<i class="fas fa-star"></i>`;
      }
      for (let index = 1; index <= remainingRating; index++) {
        returnValue +=`<i class="fal fa-star"></i>`;
      }
    returnValue+=`</span></div></div>`;
    returnValue+=`<div class="col-12 meeting-leave-comment-col m-0">
    
      <hr/>
      <div class="meeting-desc more">
      ${student_comment?.trim()}
      </div>`
    // <span>${student_comment?.trim()}</span> </div>`;
  }else if(percentage>="1"){
      returnValue +=`<div class="col-12 meeting-rating-start-col">
      <div class="meeting-rating-start d-flex">
        <input type="number"  class="rating" id=class_rating_${id} />
        <span class="ml-2">How Was Your Class ?</span>
      </div>
    </div>
    <div class="col-12 meeting-leave-comment-col">
        <div class="meeting-leave-comment">
            <textarea rows="2" class="form-control" placeholder="leave a comment" id=class_comment_${id}></textarea>
            <a class="btn btn-primary submit-rating-global-calendar" data-id="${id}" >Submit Feedback</a>
        </div>
    </div>`;
  }
  return returnValue;
}

const schedules = [];
const buildSchedule = () => {
  let scheduleObj;
  tempClasses && tempClasses.length > 0 && tempClasses.map( item => {
      if(item.category=='time'){
        const date = {
          start:`${item.class_date} ${item.class_from}`,
          end:`${item.class_date} ${item.class_to}`
        }
         scheduleObj = {
          id: item.id,
          calendarId: '1',
          title: item.class_title,
          category: item.category,
          dueDateClass: '',
          start: date.start,
          end: date.end,
          body:  printStar(item.id,item.student_rating,item.student_comment,item.percentage),
          raw:item
        }
      }else  if(item.category=='task'){
        let date = {
          start:`${moment.unix(item.open_date_time).format("YYYY-MM-DD")} ${moment.unix(item.open_date_time).format("h:mm A")}`,
          end:`${moment.unix(item.open_date_time).format("YYYY-MM-DD")} ${moment.unix(item.open_date_time).format("h:mm A")}`
        }
         scheduleObj = {
          id: item.id,
          calendarId: '1',
          title: item.name,
          category: item.category,
          dueDateClass: '',
          start: date.start,
          end: date.end,
          body: item.name,
          raw:item
        }
        schedules.push(scheduleObj)
        date = {
          start:`${moment.unix(item.close_date_time).format("YYYY-MM-DD")} ${moment.unix(item.close_date_time).format("h:mm A")}`,
          end:`${moment.unix(item.close_date_time).format("YYYY-MM-DD")} ${moment.unix(item.close_date_time).format("h:mm A")}`
        }
         scheduleObj = {
          id: item.id,
          calendarId: '1',
          title: item.name,
          category: item.category,
          dueDateClass: '',
          start: date.start,
          end: date.end,
          body: item.name,
          raw:item
        }
      }
      schedules.push(scheduleObj)
    })
} 

buildSchedule();
useEffect(()=>{
  setHeading()
},[])

useEffect(()=>{
  if((calendarView !== 'today' && calendarView !== 'past' && calendarView !== 'upcoming') && subjectId.length && showMonthWeekDate){
    setShowCalendarLoading(true);
    let isDaily = showMonthWeekDate.toString().includes("-") ? true : false;
    let startDate = moment(isDaily ? showMonthWeekDate.split("-")[0] : showMonthWeekDate).format("YYYY-MM-DD");
    let endDate = moment(isDaily ? showMonthWeekDate.split("-")[1] : showMonthWeekDate).format("YYYY-MM-DD");
    
    const params = {
      sid: subjectId,
      filter: filter,
      start_date: startDate,
      end_date: endDate
    }
    GetSubjectEventTzCalender(params).then(res => {
      setClasses(res.data);
      setTempClasses(res.data);
      setShowCalendarLoading(false);
    }).catch(error => {
      console.error(error);
      setShowCalendarLoading(false);
    })
    let tempArray = [];
    classes.map(item => {
      if(subjectId.includes(item.subjectId)){
        tempArray.push(item);
      }
    });
    setTempClasses(tempArray);
  }
},[filter, showMonthWeekDate]);

const setHeading = () =>{
  let startDate=moment(calendarRef?.current?.calendarInst?.getDateRangeStart()?.toDate()).format("D MMMM 'YY");
  let endDate=moment(calendarRef?.current?.calendarInst?.getDateRangeEnd()?.toDate()).format("D MMMM 'YY");
  // console.log("______ start:",startDate);
  // console.log("______ end:",endDate);
  // console.log("___________________ calvuew",calendarView)
  if(calendarView=='day'){
    setShowMonthWeekDate(startDate);
  }else{
    setShowMonthWeekDate(startDate+' - '+endDate);
  }
}

const moveToTodayRange = () => {
  calendarRef.current.calendarInst.today()
  setHeading();
  setTodayCalendar(true);
}

const moveToPrevRange = () => {
  calendarRef.current.calendarInst.prev()
  setHeading();
  setTodayCalendar(false);

}
const moveToNextRange = () => {
  calendarRef.current.calendarInst.next()
  setHeading();
  setTodayCalendar(false);
}

const setCalendarViewFunc = (e) => {
  // console.log("______________",e.currentTarget.value);
  setCalendarView(e.currentTarget.value);
  if(e.currentTarget.value=='upcoming'){
    setShowMonthWeekDate("Upcoming Classes");
  }
  else if(e.currentTarget.value=='past'){
    setShowMonthWeekDate("Past Classes");
  }
  else if(e.currentTarget.value=='month'){
    calendarRef.current && calendarRef.current.calendarInst && calendarRef.current.calendarInst.changeView(`${e.currentTarget.value}`,true);
    calendarRef.current && calendarRef.current.calendarInst && calendarRef.current.calendarInst.setOptions({month:{workweek:workWeek}},true);
    setTimeout(() => {
      setHeading()
    }, 0);
  }else if(e.currentTarget.value=='week'){
    calendarRef.current && calendarRef.current.calendarInst && calendarRef.current.calendarInst.changeView(`${e.currentTarget.value}`,true);
    calendarRef.current && calendarRef.current.calendarInst && calendarRef.current.calendarInst.setOptions({week:{workweek:workWeek}},true);
    setTimeout(() => {
      setHeading()
    }, 0);
  }else{
    calendarRef.current && calendarRef.current.calendarInst && calendarRef.current.calendarInst.changeView(`${e.currentTarget.value}`,true);
    setTimeout(() => {
      setHeading()
    }, 0);
  }
}

const setWorkWeekFunc = (e) => {
  setWorkWeek(e.currentTarget.checked);
  if(calendarView=='month'){
    calendarRef.current && calendarRef.current.calendarInst &&  calendarRef.current.calendarInst.setOptions({month:{workweek:e.currentTarget.checked}},true);
    calendarRef.current && calendarRef.current.calendarInst && calendarRef.current.calendarInst.changeView(`month`,true);
    setTimeout(() => {
      setHeading()
    }, 0);
  }else if(calendarView=='week'){
    calendarRef.current && calendarRef.current.calendarInst && calendarRef.current.calendarInst.setOptions({week:{workweek:e.currentTarget.checked}},true);
    calendarRef.current && calendarRef.current.calendarInst && calendarRef.current.calendarInst.changeView(`week`,true);
    setTimeout(() => {
      setHeading()
    }, 0);
  }else{
    //calendarRef.current && calendarRef.current.calendarInst.setOptions({day:{workweek:e.currentTarget.checked}},true);
  }
}

const setFilterFunc = (e) => {
  setFilter(e.currentTarget.value);
}

const moveToTodayRangeList = () => {
  setnextcount(1);
  setprevcount(1);
  const startDate = new Date(moment().startOf("week"));
  const endDate = new Date(moment().endOf("week"));
  setShowMonthWeekDate(
    moment(startDate).format("D MMMM 'YY") +
      " - " +
    moment(endDate).format("D MMMM 'YY") 
  );
  setCalendarView("today");
  setStartDateListView(moment(startDate).format("YYYY-MM-DD"));
  setEndDateListView(moment(endDate).format("YYYY-MM-DD"));

};

const moveToPrevRangeList = (val) => {
  const startDate = new Date(moment().subtract(val, "weeks").startOf("week"));
  const endDate = new Date(moment().subtract(val, "weeks").endOf("week"));
  setShowMonthWeekDate(
    moment(startDate).format("D MMMM 'YY") +
      " - " +
    moment(endDate).format("D MMMM 'YY")
  );
  setStartDateListView(moment(startDate).format("YYYY-MM-DD"));
  setEndDateListView(moment(endDate).format("YYYY-MM-DD"));
};

const moveToNextRangeList = (val) => {
  const startDate = new Date(moment().add(val, "weeks").startOf("week"));
  const endDate = new Date(moment().add(val, "weeks").endOf("week"));
  setShowMonthWeekDate(
    moment(startDate).format("D MMMM 'YY") +
      " - " +
      moment(endDate).format("D MMMM 'YY")
  );
  setStartDateListView(moment(startDate).format("YYYY-MM-DD"));
  setEndDateListView(moment(endDate).format("YYYY-MM-DD"));
};


// useEffect(() => {
//   setShowCalendarLoading(true);
//   GetSubjectEvent({sid:subjectId,filter:filter})
//   .then((res) => {
//     setClasses(res.data);
//     setTempClasses(res.data);
//     setShowCalendarLoading(false);
//   })
//   .catch((error) => {
//     setShowCalendarLoading(false);
//   });
//   let tempArray=[];
//   classes.map(item => {
//     if(subjectId.includes(item.subjectId)){
//       tempArray.push(item);
//     }
//   })
//   setTempClasses(tempArray);
// },[filter, popupUpdate]) 

// useEffect(() => {
//   setShowCalendarLoading(true);
//   let tempArray=[];
//   classes.map(item => {
//     if(subjectId.includes(item.subjectId)){
//       tempArray.push(item);
//     }
//   })
//   setTempClasses(tempArray);
//   $("#prev-button").trigger("click");
//   $("#next-button").trigger("click"); 
//   setShowCalendarLoading(false); 
// },[subjectId.length]) 

useEffect(() => {
if(calendarRef!==null && calendarView!='today' && calendarView!='upcoming' && calendarView!='past' ){
    setTimeout(() => {
      // $("#prev-button").trigger("click");
      // $("#next-button").trigger("click");
      //$("#today-button").trigger("click");
      //calendarRef.current.calendarInst.scrollToNow()
      }, 30);
      
  }else{
    includeRatingFunction();
  }
},[calendarRef,popupUpdate,calendarView,workWeek]) 

const includeRatingFunction=()=>{
  $('input.rating[type=number], div.rating').each(function () {
    $(this).rating();
  });

  $('.more').each(function() {
    let content = $(this).html()?.replace(/<[^>]+>/g, ' ');
    if(content.length > showChar) {
      let c = content.substr(0, showChar);
      let h = content.substr(showChar-1, content.length - showChar);
      let html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink show-cls-custom">' + moretext + '</a></span>';
      $(this).html(html);
    }
  });
}
  return (
    <>
    {showCalendarLoading && <SekletonGlobalCalendar/>} 
        {!showCalendarLoading &&
         (calendarView=='today' || calendarView=='past' || calendarView=='upcoming')? (
         <div className="meeting-custom-calendar">
            <div className="button-grp form-group">
            <button title="Today" className={`btn btn-white-bordered ${
                                calendarView == 'today'
                                  ? "active"
                                  : ""
                              }`} id="today-button"  value="today" onClick={() => moveToTodayRangeList()}><i className="fal fa-calendar-day"></i>Today</button>
            {
                calendarView=='today' ? (
                  <button title="Previous" className={`btn btn-white-bordered `}
                   id="prev-button"  value="previous" onClick={() => {
                    setprevcount(countprev + 1);
                    setnextcount(-countprev + 1);
                    moveToPrevRangeList(countprev);
                  }}><i className="far fa-arrow-left"></i></button>
                  )
                  :('')
              }
              {
                calendarView=='today' ? (
                  <button title="Next" className={`btn btn-white-bordered`}
                   id="next-button"  value="next"  onClick={() => {
                    setnextcount(countnext + 1);
                    setprevcount(-countnext + 1);
                    moveToNextRangeList(countnext);
                  }}><i className="far fa-arrow-right"></i></button> 
                  )
                  :('')
              }
            <div className="current-date">
              {showMonthWeekDate}
            </div>
            <select  
              className="form-control"  
              onChange={(e) => setCalendarViewFunc(e)}>
              <option value="upcoming" >Upcoming</option> 
              <option value="past" >Past</option> 
              <option value="week">Calendar</option>    
            </select>
            <div className="calendar-view-buttons">
                <button title="Upcoming" className={`btn btn-white-bordered ${
                                calendarView == 'upcoming'
                                  ? "active"
                                  : ""
                              }`} value="upcoming" id="upcoming-button" onClick={(e) => setCalendarViewFunc(e)}><i className="fal fa-arrow-right"></i>Upcoming</button>
                <button title="Past" className={`btn btn-white-bordered ${
                                calendarView == 'past'
                                  ? "active"
                                  : ""
                              }`} value="past" id="past-button" onClick={(e) => setCalendarViewFunc(e)}><i className="fal fa-history"></i>Past</button> 
                <button title="Calendar" className={`btn btn-white-bordered ${
                                calendarView == 'week'
                                  ? "active"
                                  : ""
                              }`} value="week" id="weekly-button" onClick={(e) => setCalendarViewFunc(e)}><i className="fal fa-calendar-alt"></i>Calendar</button>
            {showFilter? 
            <div class="filter">
            <label>Filter</label>
            <select  
              onChange={(e) => setFilterFunc(e)}>
              <option value="all">All events</option>
              <option value="classes" >Online Classes</option> 
              <option value="assessments" >Assessments</option> 
              <option value="dayEvents">Important Dates/Event</option>    
            </select>
            </div>:
            <></>
            }
            </div>
          </div>
          <GlobalCalendarListView filter={filter} subjectId={subjectId} status={calendarView} startDateListView={startDateListView} endDateListView={endDateListView}/>
          </div>):
        (
          <div className="meeting-custom-calendar">
            <div className="button-grp form-group">
            <button title="Today" className={`btn btn-white-bordered ${
                                todayCalendar == true
                                  ? "active"
                                  : ""
                              }`} id="today-button" onClick={() => moveToTodayRange()}><i className="fal fa-calendar-day"></i>Today</button>
            <button title="Previous" className="btn btn-white-bordered" id="prev-button" onClick={() => moveToPrevRange()}><i className="far fa-arrow-left"></i></button>
            <button title="Next" className="btn btn-white-bordered" id="next-button" onClick={() => moveToNextRange()}><i className="far fa-arrow-right"></i></button> 
            <div className="current-date">
              {showMonthWeekDate}
            </div>
            <select  
              className="form-control"  
              onChange={(e) => setCalendarViewFunc(e)}>
              <option value="month">Monthly</option>
              <option value="week" >Week</option> 
              <option value="day" >Daily</option> 
              <option value="upcoming">List</option>    
            </select>
            <div className="calendar-view-buttons">
              <button title="Monthly" className={`btn btn-white-bordered ${
                                calendarView == 'month'
                                  ? "active"
                                  : ""
                              }`} value="month" id="monthly-button" onClick={(e) => setCalendarViewFunc(e)}><i className="fal fa-calendar-alt"></i>Monthly</button>
              <button  title="Weekly" className={`btn btn-white-bordered ${
                                calendarView == 'week'
                                  ? "active"
                                  : ""
                              }`} value="week" id="weekly-button" onClick={(e) => setCalendarViewFunc(e)}><i className="fal fa-calendar-week"></i>Weekly</button>
              <button  title="Daily" className={`btn btn-white-bordered ${
                                calendarView == 'day'
                                  ? "active"
                                  : ""
                              }`} value="day" id="daily-button" onClick={(e) => setCalendarViewFunc(e)}><i className="fal fa-calendar-day"></i>Daily</button> 
              <button  title="List" className={`btn btn-white-bordered ${
                                calendarView == 'upcoming'
                                  ? "active"
                                  : ""
                              }`} value="upcoming" id="list-button" onClick={(e) => setCalendarViewFunc(e)}><i className="fal fa-list"></i>List</button> 
            </div>
            <div className="custom-control custom-checkbox text-left">
                <input type="checkbox" className="custom-control-input" onClick={(e) => setWorkWeekFunc(e)} id="exclude-weekend"/>
                <label className="custom-control-label" htmlFor="exclude-weekend">Exclude Weekends</label>
            </div>
            {showFilter? <div class="filter">
            <label>Filter</label>
            <select  
              onChange={(e) => setFilterFunc(e)}>
              <option value="all">All events</option>
              <option value="classes" >Online Classes</option> 
              <option value="assessments" >Assessments</option> 
              <option value="dayEvents">Important Dates/Event</option>    
            </select>
            </div>:
            <></>
            }
          </div>
          <div className="calendar-box">
           <Calendar
              height="600px"
              ref={calendarRef} 
              usageStatistics={true} 
              useCreationPopup={true} 
              useDetailPopup={true} 
              schedules={schedules} 
              onClickSchedule = {()=> includeRatingFunction()} 
              {...calendarOptions} 
            />                                      
            
          </div>
        </div>)}
      
    </>
  );
};
export default GlobalCalendar;
