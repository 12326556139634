import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const TABLE_DATE_FORMAT = 'DD MMM YY';
const DateRangePicker = ({
    state,
    setState,
    filterName
}) => {

    const handleDateChange = (date) => {
        if (!state?.startDate || (state?.startDate && state?.endDate)) {
            setState({...state, startDate:date, endDate:null})
        } else if (state?.startDate && !state?.endDate && date > state?.startDate) {
            
            setState({...state, endDate:date})
        } else {
            setState({...state, startDate:date, endDate:null})
            
        }
    };

    const formatDate = (date) =>{
        return date ? moment(date, TABLE_DATE_FORMAT).format(TABLE_DATE_FORMAT) : ""
    }

    const dateValue = state?.startDate && state?.endDate ? `${formatDate(state?.startDate)} - ${formatDate(state?.endDate)}` : formatDate(state?.startDate);

    return (
        <div className='assessment-table-filter'>
            <DatePicker
                selected={state?.startDate}
                onChange={handleDateChange}
                startDate={state?.startDate}
                endDate={state?.endDate}
                className='form-control table-date-filter custom-date-range-filter'
                selectsRange
                // shouldCloseOnSelect={!(state?.startDate == null)}
                shouldCloseOnSelect={false}
                minDate={state?.startDate}
                placeholderText={`Select ${filterName}`}
                value={dateValue}
                title={filterName}
                showYearDropdown
                scrollableYearDropdown
                dateFormatCalendar="MMMM"
                timeIntervals={15}
                onChangeRaw={(e)=>{e.preventDefault();}}            
            />
        </div>
    );
};

export default DateRangePicker;
