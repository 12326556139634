import React from "react";
import { Scrollbar } from "react-scrollbars-custom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-custom-scroll/dist/customScroll.css";
import QualificationHeadBox from "../components/course/QualificationHeadBox";
import SubjectListBox from "../components/course/SubjectListBox";
import SubjectContent from "../components/course/SubjectContent";
import { connect } from "react-redux";
import { setSubject } from "../store/actions";
import {
  GetSubjectsService, 
  GetSubjectContentServiceAdmin,
  getSubjects,
} from "../services/QualificationService";
import SkeletonSubject from "../loaders/SkeletonSubject";
import SkeletonQaulificationBox from "../loaders/SkeletonQualificationBox";
import moment from "moment-timezone";
import { ADMIN_PASSWORD } from "../utils/Constants";
import ReactDynamicModal from "react-draggable-resizable-modal";
import ReactPlayer from "react-player";
import PastSubjectList from "../components/course/PastSubjectList";
import LeasonListLearningMaterial from "../components/course/LeasonListLearningMaterial";
import SekletonSubjectList from "../loaders/SekletonSubjectList";
var $ = require("jquery");
// moment.tz.setDefault("Africa/Johannesburg");
let ws = null;
//let chat = null;
/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setSubject: (payload) => dispatch(setSubject(payload)),
  };
}

class Course extends React.Component {
constructor(props) {
    super(props);
    this.state = {
      showProgress:false,
      responseData: {},
      learningTab:false,
      subjects: [],
      completed_subjects: [],
      subjectContent: {},
      chat: {},
      loading: false,
      subjectContentLoading:true,
      active: 0,
      initialClick: false,
      subjectId: 0,
      reload: 0,
      subjectListLoading: true,
      reduceCommentCount: 0,
      room_id: 0,
      isExamLaunched:false,
      assessmentId: 0,
      backToOverview : 0,
      programId:0,
      QuizId: "",
      rId:"",
      assemblies:[],
      moduleId:"",
      mounted:false,
      nextModuleId:0,
      currentLesson:"",
      // showNextLesson:false,
      subassembliesItems:[],
      activeTab:"",
      currentModuleId:"",
      nextModuleId:"",
      assessmentActive:false,
      assembliesItems:[],
      assembly__category__list:[],
      lessonList:[],
      show: {
        cards: true,
        list: false,
        desc: false,
        question: false,
        review: false,
        attempt: false,
      },
      showLearningOrAssessment:false
    };
    this.showCompleted = React.createRef();
  }
  // static getDerivedStateFromProps(props, state){
  //   let start = document.getElementById("howToJoinFunction");
  //   console.log('the element is found in eventlistener ', start)

  //   if(start){
  //     start.addEventListener("click", async function () {
  //       let video = start.getAttribute("data-id");
  //       let type = start.getAttribute("data-type");
  //       let link = `https://player.vimeo.com/video/${video}`;
  //       if (type == 'youtube') {
  //         link = `https://www.youtube.com/watch?v=${video}`
  //       }
  //       return {
  //         model:state.playerModelBox(link)
  //       }
  //     });
  //   }
  //   return null
  // }
  
  // closeModal = () => {
  //   this.setState({modalOpen: false, videoId: ""});
  // };
  // playerModelBox =(links)=>{
  //   this.setState({modalOpen: true, videoId: links});
  // }

  updateShowState = (newValues) => {
    this.setState((prevState) => ({
      show: {
        ...prevState.show,
        ...newValues,
      },
    }));
  };

  componentDidMount() {
    const { match } = this.props;
    this.setState({ loading: true });
    if (this.props.location.pathname.includes("shortcourse")) {
      this.getSubContentMethod(match.params.sid);
        this.setState({ subjectId: match.params.sid });
        this.setState({ subjectListLoading: false });
        this.setState({ loading: false });
    } else {
      getSubjects(match.params)
        .then((res) => {
          // debugger;
         
          if (
            res.data.subjects.others.length <= 0 &&
            res.data.subjects.finised.length > 0
          ) {
            this.setState({ initialClick: true });
          }
          let initialID;
          if(match.params.sid==undefined){
             initialID =
            res.data.subjects.others.length > 0
              ? res.data.subjects.others[0].id
              : res.data.subjects.finised.length > 0
              ? res.data.subjects.finised[0].id
              : 58;
          }else{
             initialID=match.params.sid;
  
          }
          this.getSubContentMethod(initialID);
          this.setState({ subjectId: initialID });
          this.setState({
            qualification: res.data.qualification,
            subjects: res.data.subjects.others,
            completed_subjects: res.data.subjects.finised,
          });
  
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000);
        })
        .catch((error) => {
        });
    }
   
  }
  updateSubject = () => {
    const { match } = this.props;
    getSubjects(match.params)
      .then((res) => {
        this.setState({
          qualification: res.data.qualification,
          subjects: res.data.subjects.others,
          completed_subjects: res.data.subjects.finised,
        });
        GetSubjectContentServiceAdmin({ sid: this.state.subjectId })
          .then((res) => {
            this.setState({ subjectContent: res.data });
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };
  getSubContentMethod = (sid) => {
    //debugger;
    if (this.state.active !== sid) {
      $("body").removeClass("hide-subject-sidebar");
      this.setState({ subjectId: sid });
      GetSubjectContentServiceAdmin({ sid })
        .then((res) => {
          this.setState({ subjectContent: res.data });
          this.setState({ active: sid });
          if (this.state.initialClick) {
            this.showCompleted.current.click();
            this.setState({ initialClick: false });
          }
        })
        .catch((error) => {});
    } else {
        GetSubjectContentServiceAdmin({ sid })
        .then((res) => {
          this.setState({ subjectContent: res.data });
          this.setState({ active: sid });
          if (this.state.initialClick) {
            this.showCompleted.current.click();
            this.setState({ initialClick: false });
          }
        })
        .catch((error) => {});
    }
  };

  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  render() {
    return (
      <>
      {
      this.props.match.params.password!=ADMIN_PASSWORD ? (
      <div className="subject-no-content">
      <div className="no-img">
      </div>
      <div className="no-content">
        <h4>Unauthorized Access</h4>
        <p>You don't have access to view this page.</p>
      </div>
    </div>):(
        <div className="content-wrapper d-flex flex-wrap without-header">
          {/* <ReactDynamicModal
            data-backdrop="static"
            initWidth={640}
            initHeight={410}
            onRequestClose={this.closeModal}
            isOpen={this.state.modalOpen}
            data={
              <div className="body">
                <ReactPlayer
                  url={this.state.videoId}
                  controls={true}
                  className="react-player"
                  width="100%"
                  height="100%"
                  pip={true}
                  stopOnUnmount={false}
                  youtube="youtube"
                />
              </div>
            }
          /> */}
          <div className="sidebar-right">
            <div className="subject-detail-wrap d-flex  h-100">
              {!this.props.location.pathname.includes("shortcourse") && <div className="sub-detail-lft bg-white h-100">
                {!this.state.loading && <button
                  className="close-btn-sidebar"
                  onClick={this.handleSideBar}
                >
                  <i className="close-bar close-bar-1"></i>
                  <i className="close-bar close-bar-2"></i>
                  <i className="close-bar close-bar-3"></i>
                </button>}
                {this.state.loading && <SkeletonQaulificationBox />}
                {!this.state.loading && !this.props.location.pathname.includes("shortCourse") ? (
                  <QualificationHeadBox isAdmin={1} show={this.state.show} updateLearningAssessment={this.updateLearningAssessment} setshow={this.updateShowState} row={this.state} showProgress={this.state.showProgress} setShowProgress={(value) => this.setState({ showProgress: value })} learningTab={this.state.learningTab} subjectContent={this.state.subjectContent} setLearningTab={(value) => this.setState({ learningTab: value })} />
                ) : null}

                <div className="sub-lft-body lesson-sub-body">
                  {(!this.state.loading && !this.state.learningTab || (this.state.learningTab && this.state.show.cards)) && (
                    <h6 className='heading-title'>
                      {!this.state.assessmentActive ? "Modules" : "Programme Courses"}
                    </h6>
                  )}
                  {(!this.state.learningTab || (this.state.learningTab && this.state.show.cards)) &&
                    <Scrollbar
                      className={`${this.state.learningTab && !this.state.show.cards
                        ? "max-height-200"
                        : this.state.showProgress && this.state.showLearningOrAssessment && this.state.responseData?.timeTable == "" ? "max-height-800"
                          : this.state.showProgress && this.state.responseData?.timeTable === "" ? "max-height-700"
                            : this.state.responseData?.timeTable === ""
                              ? "max-height-900"
                              : this.state.showProgress
                                ? "max-height-575"
                                : "max-height-400"
                        }`}
                    >
                      <div className="subject-list">
                        {this.state.loading && <SkeletonSubject />}
                        {(!this.state.loading && !this.state.learningTab || (this.state.learningTab && this.state.show.cards)) && (
                          <>
                            <SubjectListBox
                              rows={this.state.subjects}
                              parentGetSubContentMethod={this.getSubContentMethod}
                              reload={this.reload}
                              active={this.state.active}
                              qid={this.props.match.params.qid}
                              isExamLaunched={this.state.isExamLaunched}
                              setIsExamLaunchedToTrue={() => this.setState({ isExamLaunched: true })}
                              setIsExamLaunchedToFalse={() => this.setState({ isExamLaunched: false })}
                              changeSub={() => this.setState({ backToOverview: this.state.backToOverview + 1 })}
                              isAdmin={1}
                            />
                            <PastSubjectList
                              rows={this.state.completed_subjects}
                              parentGetSubContentMethod={this.getSubContentMethod}
                              reload={this.reload}
                              active={this.state.active}
                              qid={this.props.match.params.qid}
                              isExamLaunched={this.state.isExamLaunched}
                              setIsExamLaunchedToTrue={() => this.setState({ isExamLaunched: true })}
                              setIsExamLaunchedToFalse={() => this.setState({ isExamLaunched: false })}
                              changeSub={() => this.setState({ backToOverview: this.state.backToOverview + 1 })}
                              isAdmin={1}
                            />
                          </>
                        )}
                      </div>
                      {/* <div id="spacer">&nbsp;</div> */}
                    </Scrollbar>}

                    {!this.state.loading && this.state.learningTab && !this.state.show.cards &&
                      // <div className="tab-pane fade show active" id="pills-Assemblies" role="tabpanel" aria-labelledby="pills-Assemblies-tab">
                      <>
                        <div className="learning-quiz-wrap h-100">
                          <LeasonListLearningMaterial
                            assembliesItems={this.state.assembliesItems}
                            assembly__category__list={this.state.assembly__category__list}
                            setassembliesItems={(value) => this.setState({ assembliesItems: value })}
                            setSubassembliesItems={(value) => this.setState({ subassembliesItems: value })}
                            subassembliesItems={this.state.subassembliesItems}
                            currentLesson={this.state.currentLesson}
                            match={this.props.match}
                            moduleId={this.state.moduleId}
                            setModuleId={(value) => this.setState({ moduleId: value })}
                            setActiveTab={(value) => this.setState({ activeTab: value })}
                            setCurrentModuleId={(value) => this.setState({ currentModuleId: value })}
                            setNextModuleId={(value) => this.setState({ nextModuleId: value })}

                            lessonList={this.state.lessonList}
                            setLessonList={(value) => this.setState({ lessonList: value })}
                          />
                        </div>
                      </>
                      // </div>
                    }
                </div>
              </div>}
             
              <div className={`${this.props.location.pathname.includes("shortcourse") ? "sub-detail-full" : "sub-detail-rgt"} ${this.state.loading ? "p-0" : ""}`}>
              {this.state.loading && <SekletonSubjectList />}
                {!this.state.loading && this.state.subjectContent.subject ? (
                  <SubjectContent
                    setshow={this.updateShowState}
                    show={this.state.show}
                    getSubContentMethod={() =>
                      this.getSubContentMethod(this.state.subjectId)
                    }
                    subjectId={this.state.subjectId}
                    isAdmin={1}
                    setLessonList={(value) => this.setState({ lessonList: value })}
                    lessonList={this.state.lessonList}
                    setAssembly__category__list={(value) => this.setState({ assembly__category__list: value })}
                    subjectContent={this.state.subjectContent}
                    updateSubject={this.updateSubject}
                    updateSubjectOnly={this.updateSubjectOnly}
                    reduceCount={this.reduceCount}
                    isExamLaunched={this.state.isExamLaunched}
                    setIsExamLaunchedToTrue={() => this.setState({ isExamLaunched: true })}
                    setIsExamLaunchedToFalse={() => this.setState({ isExamLaunched: false })}
                    assessmentId={this.state.assessmentId}
                    qid={this.props.match.params.qid}
                    params={this.props.match}
                    backToOverview={this.state.backToOverview}
                    handleLearningTab={this.handleLearningTab}
                    learningTab={this.state.learningTab}
                    setLearningTab={(value) => this.setState({ learningTab: value })}
                    assembly__category__list={this.state.assembly__category__list}
                    assemblies={this.state.assemblies}
                    moduleId={this.state.moduleId}
                    setModuleId={(value) => this.setState({ moduleId: value })}
                    nextModuleId={this.state.nextModuleId}
                    subassembliesItems={this.state.subassembliesItems}
                    setSubassembliesItems={(value) => this.setState({ subassembliesItems: value })}
                    setAssessmentActive={(value) => this.setState({ assessmentActive: value })}
                    assembliesItems={this.state.assembliesItems}
                    setassembliesItems={(value) => this.setState({ assembliesItems: value })}
                  />                  
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(Course);
