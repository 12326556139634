export const increment = () => {
  return {
    type: "INCREMENT",
  };
};

export const decrement = () => {
  return {
    type: "DECREMENT",
  };
};

export const setUser = (payload) => {
  return {
    type: "SET_USER",
    payload,
  };
};

export const unsetUser = () => {
  return {
    type: "UNSET_USER",
  };
};

export const setPayer = (payload) => {
  return {
    type: "SET_PAYER",
    payload,
  };
};

export const setFile = (payload) => {
  return {
    type: "SET_FILE",
    payload,
  };
};

export const setSubject = (payload) => {
  return {
    type: "SET_SUBJECT",
    payload,
  };
};

export const setTab = (payload) => {
  return {
    type: "SET_TAB",
    payload,
  };
};

export const unsetTab = (payload) => {
  return {
    type: "UNSET_TAB",
    payload,
  };
};

export const selectTab = (payload) => {
  return {
    type: "SELECTED_TAB",
    payload,
  };
};

export const setChatGroups = (payload) => {
  return {
    type: "SET_MY_GROUPS",
    payload,
  };
};

export const unsetChatGroups = (payload) => {
  return {
    type: "UNSET_MY_GROUPS",
    payload,
  };
};

export const setSelectedGroupsRedux = (payload) => {
  return {
    type: "SET_SELECTED_GROUPS",
    payload,
  };
};

export const unSetSelectedGroupsRedux = (payload) => {
  return {
    type: "UNSET_SELECTED_GROUPS",
    payload,
  };
};

export const setQuiz = (payload) => {
  return {
    type: "SET_QUIZ",
    payload,
  };
};

export const setQuizComplete = (payload) => {
  return {
    type: "SET_QUIZ_COMPLETE",
    payload,
  };
};

export const clearQuiz = () => {
  return {
    type: "CLEAR_QUIZ",
  };
};

export const setUnreadTicketNotificationCount = (payload) => {
  return {
    type: "SET_UNREAD_TICKET_NOTIFICATION_COUNT",
    payload,
  };
};

export const setUnreadChatNotificationCount = (payload) => {
  return {
    type: "SET_UNREAD_CHAT_NOTIFICATION_COUNT",
    payload,
  };
};

export const setNotificationCount = (payload) => {
  return {
    type: "SET_NOTIFICATION_COUNT",
    payload,
  };
};

export const setSelectedNotification = (payload) => {
  return {
    type: "SET_SELECTED_NOTIFICATION",
    payload,
  };
};

export const setUnreadNotificationCount = (payload) => {
  return {
    type: "SET_UNREAD_NOTIFICATION_COUNT",
    payload,
  };
};

export const unsetUnreadNotificationCount = () => {
  return {
    type: "UNSET_UNREAD_NOTIFICATION_COUNT"
  };
};

export const setWidget = (payload) => {
  return {
    type: "SET_WIDGET",
    payload,
  };
};

export const unsetWidget = () => {
  return {
    type: "UNSET_WIDGET"
  };
};

export const modalOpen = (payload) => {
  return {
    type: "MODAL_OPEN",
    payload,

  };
};
export const modalClose = () => {
  return {
    type: "MODAL_CLOSE"
  };
};

export const setBrand = (payload) => {
  return {
    type: "SET_BRAND",
    payload,
  };
};

export const clearBrand = () => {
  return {
    type: "CLEAR_BRAND",
  };
};

export const setCount = (data) => {
  return {
    type: "SET_COUNT",
    payload: data
  };
};

export const setHoverUserData = (data) => {
  return {
    type: "SET",
    payload: data
  };
};
export const setUserMissingDetails = (payload) => {
  return {
    type: "SET_MISSING_DETAIL",
  };
}

export const setFavouritesCount = (payload) => {
  return {
    type: "SET_FAVOURITES_COUNT",
    payload,
  };
};

export const setReRegistraionCount = (payload) => {
  return {
    type: "SET_RE_REGISTRATION_COUNT",
    payload,
  }
}

export const setIsReReg = (payload) => {
  return {
    type: "SET_IS_RE_REG",
    payload,
  }
}
export const setIsArticulation = (payload) => {
  return {
    type: "SET_IS_ARTICULATION",
    payload,
  }
}

export const setReRegistrationsLoading = () => {
  return {
    type: "REG_LOADING"
  }
}

export const setReRegistrations = (payload) => {
  return {
    type: "SET_REG_DATA",
    payload,
  }
}

export const setReRegistrationsReload = (payload) => {
  return {
    type: "SET_REG_RELOAD",
    payload,
  }
}

export const setIsExamReg = (payload) => {
  return {
    type: "SET_IS_EXAM_REG",
    payload,
  }
}

export const setIsWithheld = (payload) => {
  return {
    type: "SET_IS_WITHHELD",
    payload,
  }
}

export const setIsExamLaunched = (payload) => {
  return {
    type: "SET_IS_EXAM_LAUNCHED",
    payload,  
  }
}

export const toggleNotes = () => {
  return {
    type: "TOGGLE_NOTES",
  };
};

export const toggleCompleteModule = () => {
  return {
    type: "TOGGLE_COMPLETE_MODULE",
  };
};

export const toggleStartModule = () => {
  return {
    type: "TOGGLE_START_MODULE",
  };
};

export const clearToggles = () =>{
  return{
    type : "CLEAR_TOGGLES"
  }
}




export const UpdateLessonType = (payload) =>{
  return{
    type : "UPDATE_LESSON_TYPE",
    payload 
  }
}

export const clearLessonType = () =>{
  return{
    type : "EMPTY_LESSON_TYPE", 
  }
}


export const updateAssessmentActive = (payload) =>{
  return{
    type : "UPDATE_ASSESSMENT_ACTIVE",
    payload 
  }
}

export const clearAssessmentActive = () =>{
  return{
    type : "EMPTY_ASSESSMENT_ACTIVE", 
  }
}


export const clearCategoryId = () =>{
  return{
    type : "EMPTY_CATEGORY_ID", 
  }
}

export const updateActivityArr = (payload) =>{
  return{
    type : "UPDATE_ACTIVITY_ARR",
    payload 
  }
}

export const clearActivityArr = () =>{
  return{
    type : "EMPTY_ACTIVITY_ARR", 
  }
}

export const updateVideoPlayer = (payload) =>{
  return{
    type : "UPDATE_VIDEO_PLAYER",
    payload 
  }
}

export const clearVideoPlayer = () =>{
  return{
    type : "EMPTY_VIDEO_PLAYER", 
  }
}

export const updateCurrentActivity = (payload) =>{
  return{
    type : "UPDATE_CURRENT_ACTIVITY",
    payload 
  }
}
export const updateSubjectContentLoadingOnLesson = (payload) =>{
  return{
    type : "UPDATE_SUBJECT_CONTENT_LOADING_ON_LESSON",
    payload 
  }
}

export const clearCurrentActivity = () =>{
  return{
    type : "EMPTY_CURRENT_ACTIVITY", 
  }
}

export const updateCurrentAssembly = (payload) =>{
  return{
    type : "UPDATE_CURRENT_ASSEMBLY",
    payload 
  }
}


export const updateCurrentCategory = (payload) =>{
  return{
    type : "UPDATE_CURRENT_CATEGORY",
    payload 
  }
}

export const clearCurrentCategory = () =>{
  return{
    type : "CLEAR_CURRENT_CATEGORY", 
  }
}

export const updateVideoDuration = (payload) =>{
  return{
    type : "UPDATE_VIDEO_DURATION",
    payload 
  }
}

export const clearVideoDuration = () =>{
  return{
    type : "CLEAR_VIDEO_DURATION", 
  }
}

export const clearIsDownloadAssessment=()=>{
  return{
    type : "CLEAR_IS_DOWNLOAD_ASSESSMENT",
  }
}

export const updateIsDownloadAssessment=(payload)=>{
  return{
    type : "UPDATE_IS_DOWNLOAD_ASSESSMENT",
    payload
  }
}