import React from 'react'
import DatePicker from "react-datepicker";

const FORM_DATE_FORMAT = 'd MMM yy';
export default function TableDateFilter({
    state,
    setState,
    filterName
}) {
    const filterFunction = (date) =>{
        setState(date);
    }

    return (
        <div className='assessment-table-filter'>
            <DatePicker
                autoComplete='off'
                selected={state}
                name="startDate"
                onChange={(date)=>{setState(date)}}
                dateFormat={FORM_DATE_FORMAT}
                showYearDropdown
                scrollableYearDropdown
                dateFormatCalendar="MMMM"
                className='form-control table-date-filter custom-date-filter'
                title={filterName}
                placeholderText={`Select ${filterName}`}
                timeIntervals={15}                
                onChangeRaw={(e)=>{e.preventDefault();}}
            />
        </div>
    )
}
