import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { getRandomInt } from "../../utils/commonFunction.js";
import { download } from "../../utils/FileDownload";
import ReactHtmlParser from "react-html-parser";
//import Popup from 'reactjs-popup';
//import 'reactjs-popup/dist/index.css';
import { Popup } from "semantic-ui-react";
//import 'semantic-ui-css/semantic.min.css'
//import ShowMoreText from 'react-show-more-text';
import ReactTooltip from "react-tooltip";
import {
  GetSubjectEventPageWise,
} from "../../services/CalendarService";
import $ from "jquery";
import moment from "moment-timezone";
import "../../assets/js/rating.js";
import Pagination from "react-js-pagination";
import SekletonGlobalCalendarListView from "../../loaders/SekletonGlobalCalendarListView";
import PopupComponent from "../common/PopupComponent";
import GlobalCalendarListItem from "./GlobalCalendarListItem";
import { RenderFeedAssessmentStatus} from "../ticket/StatusRender.js";
import {classStatusWidgets} from "./../common/Helper";

const stc = require('string-to-color');

// moment.tz.setDefault("Africa/Johannesburg");
$(document).on("click", ".class-list-row", function () {
  setTimeout(() => {
    $("input.rating[type=number], div.rating").each(function () {
      $(this).rating();
    });
  }, 100);
});
const GlobalCalendarListView = ({
  subjectId,
  status,
  startDateListView,
  endDateListView,
  filter,
  }) => {
  const location = useHistory();
  let sD = moment().startOf("week");
  let eD = moment().endOf("week");

  const [showMore, setShowMore] = useState(false);
  const [popupUpdate, setPopupUpdate] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [classes, setClasses] = useState([]);
  const [classesKey, setClassesKey] = useState([]);
  const [classData, setClassData] = useState({})
  const [showListLoading, setShowListLoading] = useState(false);

  const handlePageChange = (pageNumber) => {
    if (pageNumber) {
      setShowListLoading(true);
      setActivePage(pageNumber);
      GetSubjectEventPageWise({
        subjectId,
        pageNumber,
        itemsPerPage,
        status,
        startDateListView,
        endDateListView,
        filter:filter,
      })
        .then((res) => {
          setClasses(Object.values(res.data.finalArray));
          setClassesKey(Object.keys(res.data.finalArray));
          setTotalItems(res.data.length);
          setShowListLoading(false);
        })
        .catch((error) => {
          // setShowListLoading(false);
        });
    }
  };
  useEffect(() => {
    let pageNumber = 1;
    handlePageChange(pageNumber)
  }, [subjectId.length, status, startDateListView, popupUpdate,filter]);

  const goToSubject= (qid,sid)=>{
    location.push(`/course/${qid}/subject/${sid}`);
  };

  return (
    <>
      {showListLoading && <SekletonGlobalCalendarListView/>} 
      {!showListLoading &&
      <div className="Class-Shedule-List-View">
        <div className="table-responsive">
          <table className="table Class-Shedule-main-table">
            <tbody>
              {classesKey &&
                classesKey.map((item, index1) => {
                  let res = item.split(",");
                  return (
                    <tr key={item + index1}>
                      <td>
                        <table className="table table-bordered">
                          <thead className="thead-dark">
                            <tr>
                              <th colSpan="2">
                                {moment(item, ["YYYY-MM-DD"]).format("dddd, D MMMM 'YY")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classes[index1] &&
                              classes[index1].map((row, index11) => {
                                let styles = {
                                  color: stc(row.subjectName)
                                };
                                let currentTime = moment().unix();
                                if(row.category=='task'){
                                  let assignmentid="Ass_"+row.id;
                                  return (
                                      <tr
                                        className={`class-list-row class-past-row assessment-list-row`}
                                        key={row.id + "" + index11}
                                      >
                                        <td>
                                            {
                                            moment.unix(row.open_date_time).format("YYYY-MM-DD") == moment.unix(row.close_date_time).format("YYYY-MM-DD") ? 
                                            (moment.unix(row.open_date_time).format("h:mm A")+"-"+moment.unix(row.close_date_time).format("h:mm A")):
                                            moment.unix(row.open_date_time).format("YYYY-MM-DD") == item ?
                                            (moment.unix(row.open_date_time).format("h:mm A")):
                                            (moment.unix(row.close_date_time).format("h:mm A"))
                                            }
                                            <div>
                                              <br/>
                                              {moment.unix(row.open_date_time).format("YYYY-MM-DD-HH-mm-ss") > moment.unix(currentTime).format("YYYY-MM-DD-HH-mm-ss") ? RenderFeedAssessmentStatus("pending").html : moment.unix(row.close_date_time).format("YYYY-MM-DD-HH-mm-ss") < moment.unix(currentTime).format("YYYY-MM-DD-HH-mm-ss") ? RenderFeedAssessmentStatus("closed").html : RenderFeedAssessmentStatus("open").html}
                                              {/* {row?.status == "Open" ? RenderFeedAssessmentStatus(row?.status).html: row?.status == "Closed Not-Submitted" || row?.status == "Closed-Submitted" || row?.status == "Closed-Marked" || row?.status == "Closed-Submitted" ? RenderFeedAssessmentStatus("closed").html :  <></> } */}
                                            </div>
                                        </td>
                                        <td className={`${row.category == "task" && (moment.unix(row.open_date_time).format("YYYY-MM-DD-HH-mm-ss") > moment.unix(currentTime).format("YYYY-MM-DD-HH-mm-ss") ? "" : moment.unix(row.close_date_time).format("YYYY-MM-DD-HH-mm-ss") < moment.unix(currentTime).format("YYYY-MM-DD-HH-mm-ss") ? "assessment-list-row-color-red" : "assessment-list-row-color-blue")}`}>
                                            <GlobalCalendarListItem row={row} itemType="assessment" />
                                        </td>
                                      </tr>
                                )
                                }else if(row.category=='time'){
                                let sst = moment(row.class_from, [
                                  "h:mm A",
                                ]).format("HH:mm:ss");
                                let eet = moment(row.class_to, [
                                  "h:mm A",
                                ]).format("HH:mm:ss");
                                let st = moment(row.class_date + " " + sst);
                                let et = moment(row.class_date + " " + eet);
                                let start_time = st.unix();
                                let end_time = et.unix();
                                let start_time_live = moment(st)
                                  .subtract(15, "minutes")
                                  .unix();
                                
                                return row.canceled == 1 || (row.started_at == 0 && row.class_status == "Past")? ( 
                                  
                                      <tr
                                        className="class-list-row class-canceled-row"
                                        key={row.id + "" + index11}
                                      >
                                        <td className="class-canceled-row-td">
                                          {row.class_from}-{row.class_to}
                                        </td>
                                        <td>
                                        <div className="class-list-data">
                                        <div className="meeting-title system-bot-inner-R-title gapCustom-8">

                                              {row.class_type == "hybrid" ? (
                                                  <>
                                                      <h6 className="meeting-title lb-black pb-xl-0">
                                                          {row.class_title}
                                                      </h6>
                                                          <small className="tag class-status-tag">
                                                              <i className="fal fa-university"></i>{" "}
                                                              <i className="fal fa-plus"></i>{" "}
                                                              <i className="fal fa-house-signal"></i>Hybrid
                                                          </small>
                                                  </>
                                              ) : row.class_type == "on_campus" ? (
                                                  <>
                                                      <h6 className="meeting-title lb-black pb-xl-0">
                                                          {row.class_title}
                                                      </h6>
                                                      <small className="tag-purple class-status-tag">
                                                          <i className="fal fa-university"></i> On Campus
                                                      </small>
                                                  </>
                                              ) : (
                                                  <div className="virtal-class-row gapCustom-8">

                                                      <h6 className="meeting-title lb-black pb-xl-0">
                                                          {row.class_title}
                                                      </h6>
                                                      <small className="tag-green class-status-tag">
                                                          <i className="fal fa-house-signal"></i> Virtual
                                                      </small>

                                                  </div>)
                                              }
                                          <small>
                                          {classStatusWidgets("cancelled")}
                                          </small>
                                        </div>
                                        </div>
                                        </td>
                                      </tr>
                                    
                                ) : end_time < currentTime ? (
                                  //Past Classes
                                  <>
                                      <tr
                                        className="class-list-row class-past-row"
                                        key={row.id + "" + index11}
                                      >
                                        <td>
                                          {row.class_from}-{row.class_to}
                                        </td>
                                        <td>
                                        <GlobalCalendarListItem row={row} itemType="class" classtype="past" classData={classData} setClassData={setClassData} classes={classes} setPopupUpdate={setPopupUpdate}/>
                                        </td>
                                      </tr>
                                    
                                  </>
                                ) : start_time_live <= currentTime &&
                                end_time >= currentTime ? (
                                  //Live Classes
                                  
                                      <tr className="class-list-row class-live-row">
                                        <td>
                                          {row.class_from}-{row.class_to}
                                        </td>
                                        <td>
                                        <GlobalCalendarListItem row={row} itemType="class" classtype="live" classData={classData} setClassData={setClassData} classes={classes} setPopupUpdate={setPopupUpdate}/>
                                       </td>
                                      </tr>
                                   
                                ) : (
                                  //Upcoming Classes
                                      <tr
                                        class="class-list-row class-upcoming-row"
                                        key={row.id + "" + index11}
                                      >
                                        <td>
                                          {row.class_from}-{row.class_to}
                                        </td>
                                        <td>
                                        <GlobalCalendarListItem row={row} itemType="class" classtype="upcoming" classes={classes} setPopupUpdate={setPopupUpdate} />
                                        </td>
                                      </tr>
                                    );
                              }
                              })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  );
                })}
              {classesKey.length == 0 && (
                <tr>
                  <td>
                    <table className="table table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>No classes found.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="class-list-row">
                          <td>
                            We could not find any classes. Please confirm with
                            your Support Team or try again later.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      }
      {(status == "past" || status == "upcoming") && classesKey.length > 0 ? (
        <div className="class-list-view-pagination mt-3">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => handlePageChange(pageNumber)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default GlobalCalendarListView;






